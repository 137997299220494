let mutations = {
  RESTORE_MAIN_STATES: (state) => {
    Object.assign(state, {
      // Current company information
      companyInformation: {
        _id: null,
        name: null,
        NO_FACTURACION: null,
        paymentPlan: null,
        NOMBRE_FAC: null,
        CERTIFICADO_SAT: null,
        LLAVE_SAT: null,
        CLAVE_LLAVE: null,
        CLAVE_REGIMEN: null,
        RFC: null,
        CP: null,
        DOMICIL: null,
        REFERENCIA: null,
        NUM_EXTER: null,
        NUM_INTER: null,
        NOMBRE_MUNICIPIO: null,
        MUNICIPIO: null,
        ESTADO: null,
        LOCALIDAD: null,
        COLONIA: null,
        NOMBRE_COLONIA: null,
        TELS: null,
        CEL: null,
        PATER: null,
        MATER: null,
        NOMBRE: null,
        EMAIL: null,
        EMAIL_ERRORES_FACTURACION: null,
        suscribedPayment: false,
        FECHA_COBRO: null,
        FECHA_DE_VENCIMIENTO: null,
        PLAN_PAGO: null,
        STATUS: false,
        ACTUALIZAR_LINK: false,
        availableMonthlyStamps: 0,
        availableStamps: 0,
        MOVIMIENTO_OBLIGATORIO: false,
        ENVIAR_MATERIAL_VENTA: false,
        PERMITIR_ENVIO_SIN_PAGO: false,
        AUTOCOMPLETAR_CODIGO_ALEATORIO: false,
        NO_VENDER_CUENTAS_CLIENTE: false,
        CONSOLIDAR_SALDO_AUTOMATICAMNTE: false,
        LISTA_PRECIOS_PRE: "",
        POR_SUP_REVISION_COMPRAS: 0,
        POR_INF_REVISION_COMPRAS: 0,
        FONDO: "",
        FONDO_SECUNDARIO: "",
        TEXTO: "",
        TEXTO_SECUNDARIO: "",
        LEYENDA_SUPERIOR: "",
        LEYENDA_INFERIOR: "",
        LOGO_ID: "",
        LOGO_URL: "",
        NOT_COMPRAS: [],
        NOT_COMPRAS_CON: [],
        NOT_VENTAS: [],
      },
      nextPaymentDate: false,
      //User already logged in
      loggedIn: false,
      //Data already loaded to the store, used for router validation
      loaded: false,
      //Display loading screen
      loading: false,
      //Loding message
      loadingMessage: "Cargando...",
      //Url where the backend is located
      backenUrl: "",
      //User logged in information, control information
      user: {
        name: null,
        email: null,
        permissions: null,
        token: null,
        companyMainName: null,
        validatedEmail: false,
        tourCompleted: false,
        betaMessageCompleted: false,
        ALMACENES: [],
      },
      notifications: [],
      //Sat link
      satLink: "",
      //Company KPIS
      kpis: {
        sales: {
          total: 0,
          quantity: 0,
          average: 0,
        },
        quotes: {
          total: 0,
          quantity: 0,
          average: 0,
        },
        purchases: {
          total: 0,
          quantity: 0,
          average: 0,
        },
        orders: {
          total: 0,
          quantity: 0,
          average: 0,
        },
        expensesPurchases: {
          total: 0,
          quantity: 0,
        },
        expensesOther: {
          total: 0,
          quantity: 0,
        },
        incomeSales: {
          total: 0,
          quantity: 0,
        },
        incomeOther: {
          total: 0,
          quantity: 0,
        },
      },
      graphs: {
        sales: [],
        quotes: [],
        purchases: [],
        orders: [],
        expensesPurchases: [],
        expensesOther: [],
        incomeSales: [],
        incomeOther: [],
      },
      lists: {
        mostSoldArticles: [],
        mostBoughtArticles: [],
        clientMostSales: [],
        providerMostPurchases: [],
      },
      measures: [],
      companyTotalValue: {
        inventory: 0,
        expenses: 0,
        incomes: 0,
        banks: 0,
        company: 0,
      },
      salesDate: {
        total: 0,
        quantity: 0,
        average: 0,
      },
    });
  },
  SET_LOGGED_IN: (state, payload) => {
    state.loggedIn = payload;
  },
  SET_USER: (state, payload) => {
    state.user = payload;
  },
  SET_LOADED: (state, payload) => {
    state.loaded = payload;
  },
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_LOADING_MESSAGE: (state, payload) => {
    state.loadingMessage = payload;
  },
  CHANGE_COMPANY_INFORMATION: (state, payload) => {
    const currentCompanyInformation = { ...state.companyInformation };
    currentCompanyInformation.CP = payload._id.CP;
    currentCompanyInformation.DOMICIL = payload._id.DOMICIL;
    currentCompanyInformation.REFERENCIA = payload._id.REFERENCIA;
    currentCompanyInformation.NUM_EXTER = payload._id.NUM_EXTER;
    currentCompanyInformation.NUM_INTER = payload._id.NUM_INTER;
    currentCompanyInformation.NOMBRE_MUNICIPIO = payload._id.NOMBRE_MUNICIPIO;
    currentCompanyInformation.MUNICIPIO = payload._id.MUNICIPIO;
    currentCompanyInformation.ESTADO = payload._id.ESTADO;
    currentCompanyInformation.LOCALIDAD = payload._id.LOCALIDAD;
    currentCompanyInformation.COLONIA = payload._id.COLONIA;
    currentCompanyInformation.NOMBRE_COLONIA = payload._id.NOMBRE_COLONIA;
    currentCompanyInformation.TELS = payload._id.TELS;
    currentCompanyInformation.CEL = payload._id.CEL;
    currentCompanyInformation.PATER = payload._id.PATER;
    currentCompanyInformation.MATER = payload._id.MATER;
    currentCompanyInformation.NOMBRE = payload._id.NOMBRE;
    currentCompanyInformation.EMAIL = payload._id.EMAIL;
    currentCompanyInformation.EMAIL_ERRORES_FACTURACION =
      payload._id.EMAIL_ERRORES_FACTURACION;
    currentCompanyInformation.MOVIMIENTO_OBLIGATORIO =
      payload._id.MOVIMIENTO_OBLIGATORIO;
    currentCompanyInformation.ENVIAR_MATERIAL_VENTA =
      payload._id.ENVIAR_MATERIAL_VENTA;
    currentCompanyInformation.PERMITIR_ENVIO_SIN_PAGO =
      payload._id.PERMITIR_ENVIO_SIN_PAGO;
    currentCompanyInformation.AUTOCOMPLETAR_CODIGO_ALEATORIO =
      payload._id.AUTOCOMPLETAR_CODIGO_ALEATORIO;
    currentCompanyInformation.NO_VENDER_CUENTAS_CLIENTE =
      payload._id.NO_VENDER_CUENTAS_CLIENTE;
    currentCompanyInformation.CONSOLIDAR_SALDO_AUTOMATICAMNTE =
      payload._id.CONSOLIDAR_SALDO_AUTOMATICAMNTE;
    currentCompanyInformation.POR_SUP_REVISION_COMPRAS =
      payload._id.POR_SUP_REVISION_COMPRAS;
    currentCompanyInformation.POR_INF_REVISION_COMPRAS =
      payload._id.POR_INF_REVISION_COMPRAS;
    currentCompanyInformation.LISTA_PRECIOS_PRE = payload._id.LISTA_PRECIOS_PRE;
    state.companyInformation = currentCompanyInformation;
  },
  CHANGE_COMPANY_INFORMATION_DOCUMENTS: (state, payload) => {
    const currentCompanyInformation = { ...state.companyInformation };

    currentCompanyInformation.FONDO = payload.FONDO;
    currentCompanyInformation.FONDO_SECUNDARIO = payload.FONDO_SECUNDARIO;
    currentCompanyInformation.TEXTO = payload.TEXTO;
    currentCompanyInformation.TEXTO_SECUNDARIO = payload.TEXTO_SECUNDARIO;
    currentCompanyInformation.LEYENDA_SUPERIOR = payload.LEYENDA_SUPERIOR;
    currentCompanyInformation.LEYENDA_INFERIOR = payload.LEYENDA_INFERIOR;
    currentCompanyInformation.LOGO_ID = payload.LOGO_ID;
    currentCompanyInformation.LOGO_URL = payload.LOGO_URL;

    state.companyInformation = currentCompanyInformation;
  },
  CHANGE_COMPANY_INFORMATION_NOTIFICATIONS: (state, payload) => {
    const currentCompanyInformation = { ...state.companyInformation };

    currentCompanyInformation.NOT_COMPRAS = payload.NOT_COMPRAS;
    currentCompanyInformation.NOT_COMPRAS_CON = payload.NOT_COMPRAS_CON;
    currentCompanyInformation.NOT_VENTAS = payload.NOT_VENTAS;

    state.companyInformation = currentCompanyInformation;
  },
  CHANGE_COMPANY_INVOICE_INFORMATION: (state, payload) => {
    const currentCompanyInformation = { ...state.companyInformation };
    currentCompanyInformation.NO_FACTURACION = payload._id.NO_FACTURACION;
    currentCompanyInformation.NOMBRE_FAC = payload._id.NOMBRE_FAC;
    currentCompanyInformation.CERTIFICADO_SAT = payload._id.CERTIFICADO_SAT;
    currentCompanyInformation.LLAVE_SAT = payload._id.LLAVE_SAT;
    currentCompanyInformation.CLAVE_LLAVE = payload._id.CLAVE_LLAVE;
    currentCompanyInformation.CLAVE_REGIMEN = payload._id.CLAVE_REGIMEN;
    state.companyInformation = currentCompanyInformation;
  },
  SET_COMPANY_INFORMATION: (state, payload) => {
    state.companyInformation = payload;
  },
  SET_SAT_LINK: (state, payload) => {
    state.satLink = payload;
  },
  UPDATE_SAT_LINK: (state, payload) => {
    state.companyInformation.ACTUALIZAR_LINK = false;
  },
  VALIDATE_EMAIL: (state) => {
    if (state.user.email) {
      state.user.validatedEmail = true;
    }
  },
  ACTIVATE_PAYMENT_METHOD: (state) => {
    state.companyInformation.suscribedPayment = true;
  },
  SET_COMPANY_SALES_KPI: (state, payload) => {
    state.kpis.sales = payload.totalSales;
    state.kpis.quotes = payload.totalQuotes;
  },
  SET_COMPANY_PURCHASES_KPI: (state, payload) => {
    state.kpis.purchases = payload.totalPurchases;
    state.kpis.orders = payload.totalOrders;
  },
  SET_COMPANY_EXPENSES_KPI: (state, payload) => {
    state.kpis.expensesPurchases = payload.totalExpensesPurchases;
    state.kpis.expensesOther = payload.totalExpensesOther;
  },
  SET_COMPANY_INCOMES_KPI: (state, payload) => {
    state.kpis.incomeSales = payload.totalIncomeSales;
    state.kpis.incomeOther = payload.totalIncomeOther;
  },
  SET_COMPANY_SALES_GRAPH_INFORMATION: (state, payload) => {
    state.graphs.sales = payload.sales;
    state.graphs.quotes = payload.quotes;
  },
  SET_COMPANY_PURCHASES_GRAPH_INFORMATION: (state, payload) => {
    state.graphs.purchases = payload.purchases;
    state.graphs.orders = payload.orders;
  },
  SET_COMPANY_EXPENSES_GRAPH_INFORMATION: (state, payload) => {
    state.graphs.expensesPurchases = payload.expensesPurchases;
    state.graphs.expensesOther = payload.expensesOther;
  },
  SET_COMPANY_INCOMES_GRAPH_INFORMATION: (state, payload) => {
    state.graphs.incomeSales = payload.incomesSales;
    state.graphs.incomeOther = payload.incomesOther;
  },
  SET_COMPANY_ARTICLES_LIST: (state, payload) => {
    state.lists.mostSoldArticles = payload.mostSold;
    state.lists.mostBoughtArticles = payload.mostBought;
  },
  SET_COMPANY_CLIENTS_LIST: (state, payload) => {
    state.clientMostSales = payload;
  },
  SET_COMPANY_PROVIDERS_LIST: (state, payload) => {
    state.providerMostPurchases = payload;
  },
  COMPLETE_TOUR: (state) => {
    state.user.tourCompleted = true;
  },
  COMPLETE_BETA_MESSAGE: (state) => {
    state.user.betaMessageCompleted = true;
  },
  SET_MEASURES: (state, payload) => {
    state.measures = payload;
  },
  SAVE_MEASURES: (state, payload) => {
    const currentMeasures = [...state.measures];
    currentMeasures.push(payload._id);
    state.measures = currentMeasures;
  },
  EDIT_MEASURES: (state, payload) => {
    const currentMeasures = [...state.measures];
    for (const [
      singleMeasureIndex,
      singleMeasure,
    ] of currentMeasures.entries()) {
      if (singleMeasure._id === payload._id._id) {
        currentMeasures[singleMeasureIndex] = payload._id;
      }
    }
    state.measures = currentMeasures;
  },
  DELETE_MEASURES: (state, payload) => {
    const currentMeasures = [...state.measures];
    const finalMeasures = [];
    for (const singleMeasure of currentMeasures) {
      if (!payload._id.objectsDeleted.includes(singleMeasure._id)) {
        finalMeasures.push(singleMeasure);
      }
    }
    state.measures = finalMeasures;
  },
  SET_NEXT_PAYMENT_DATE: (state, payload) => {
    state.nextPaymentDate = payload;
  },
  SET_COMPANY_TOTAL_VALUE: (state, payload) => {
    state.companyTotalValue = payload;
  },
  SET_USER_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload;
  },
  MARK_NOTIFICATION_AS_READ: (state, payload) => {
    const currentNotifications = [...state.notifications];

    for (const [
      singleNotificationIndex,
      singleNotification,
    ] of currentNotifications.entries()) {
      if (
        singleNotification._id.toString() ===
        payload._id.noitficationId.toString()
      ) {
        currentNotifications[singleNotificationIndex].status = "C";
      }
    }

    state.notifications = currentNotifications;
  },
  MARK_ALL_NOTIFICATION_AS_READ: (state, payload) => {
    const currentNotifications = [...state.notifications];

    for (const [
      singleNotificationIndex,
      singleNotification,
    ] of currentNotifications.entries()) {
      currentNotifications[singleNotificationIndex].status = "C";
    }

    state.notifications = currentNotifications;
  },
  SAVE_COMPANY_NAME: (state, payload) => {
    const currentCompanyNames = [...state.companyNames];
    currentCompanyNames.push(payload._id);
    state.companyNames = currentCompanyNames;
  },
  SET_COMPANY_NAMES: (state, payload) => {
    state.companyNames = payload;
  },
  EDIT_COMPANY_NAME: (state, payload) => {
    const currentCompanyNames = [...state.companyNames];
    for (const [
      singleCompanyNameIndex,
      singleCompanyName,
    ] of currentCompanyNames.entries()) {
      if (singleCompanyName._id === payload._id._id) {
        currentCompanyNames[singleCompanyNameIndex] = payload._id;
      }
    }
    state.companyNames = currentCompanyNames;
  },
  ACTIVATE_COMPANY_NAME: (state, payload) => {
    const currentCompanyNames = [...state.companyNames];
    for (const [
      singleCompanyNameIndex,
      singleCompanyName,
    ] of currentCompanyNames.entries()) {
      if (singleCompanyName._id === payload._id) {
        currentCompanyNames[singleCompanyNameIndex].STATUS = "AC";
      }
    }
    state.companyNames = currentCompanyNames;
  },
  DISABLE_COMPANY_NAME: (state, payload) => {
    const currentCompanyNames = [...state.companyNames];
    for (const [
      singleCompanyNameIndex,
      singleCompanyName,
    ] of currentCompanyNames.entries()) {
      if (singleCompanyName._id === payload._id) {
        currentCompanyNames[singleCompanyNameIndex].STATUS = "PE";
      }
    }
    state.companyNames = currentCompanyNames;
  },
  SET_CAUSE_CODES: (state, payload) => {
    state.causeCodes = payload;
  },
};

export default mutations;
