<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title print-hide">{{ title }}</h1>

    <div class="container-print">
      <b-field
        label="Correo a usar para enviar venta (Requerido)"
        expandeds
        label-position="inside"
        class="special-margin"
        v-if="sendClientEmail"
      >
        <b-select
          placeholder="Ejemplo: Ventas"
          icon="at"
          expanded
          v-model="selectedEmail"
        >
          <option value="" disabled>selecciona un correo</option>
          <option
            v-for="singleEmail in companyEmails"
            :value="singleEmail._id"
            :key="singleEmail._id"
          >
            {{ singleEmail.CORREO }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Correos a donde enviar venta (Opcional)"
        expanded
        label-position="inside"
        class="special-margin required-input"
        v-if="sendClientEmail"
      >
        <b-taginput
          v-model="clientSendEmails"
          ellipsis
          icon="label"
          placeholder="Correos para envio de venta"
          :before-adding="(tagToAdd) => validateEmail(tagToAdd)"
        >
        </b-taginput>
      </b-field>
    </div>

    <div class="container-print" ref="saleQuote">
      <img
        v-if="companyInformation.LOGO_URL"
        :src="companyInformation.LOGO_URL"
        alt="Company logo"
        style="margin-top: 1rem; width: 20%; margin-bottom: 1rem"
      />
      <p class="special-margin">
        <strong>
          {{
            companyInformation.LEYENDA_SUPERIOR
              ? companyInformation.LEYENDA_SUPERIOR
              : ""
          }}</strong
        >
      </p>
      <table width="100%">
        <tr class="logotype-container">
          <td>
            <div class="logotype-type" :style="[backgroundStyle, textStyle]">
              {{ title }}
            </div>
          </td>
        </tr>
      </table>
      <br />

      <div style="margin-bottom: 20px">
        <strong>Información de la empresa</strong><br />
      </div>
      <table width="100%">
        <tr>
          <td>
            <table>
              <tr>
                <td>
                  <strong>Nombre:</strong>
                  {{ companyName }} <br />
                  <strong>Almacén origen:</strong>
                  {{ saleInformation.ALMACEN_ORIGEN_INFO.NOMBRE_ALM }}
                  <br />
                  <strong>Dirección:</strong>
                  {{ saleInformation.ALMACEN_ORIGEN_INFO.DOMICIL || "N/A" }}
                  {{
                    saleInformation.ALMACEN_ORIGEN_INFO.NOMBRE_MUNICIPIO ||
                    "N/A"
                  }},
                  {{ saleInformation.ALMACEN_ORIGEN_INFO.ESTADO || "N/A" }}
                  {{ saleInformation.ALMACEN_ORIGEN_INFO.CP || "N/A" }}<br />
                  <strong>Teléfono: </strong>
                  {{ saleInformation.ALMACEN_ORIGEN_INFO.CEL_ALM || "N/A" }}
                  <br />
                  <strong>Correo: </strong>
                  {{ saleInformation.ALMACEN_ORIGEN_INFO.EMAIL || "N/A" }}
                  <br />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <br />
      <div
        style="padding-left: 20px; padding-top: 10px; padding-bottom: 10px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Información del cliente</strong><br />
      </div>
      <table width="100%">
        <tr>
          <td style="padding: 20px; width: 50%">
            <strong>Número:</strong>
            {{ saleInformation.CLAVE_CLIENTE.CLAVE_CLI }}<br />
            <strong>Nombre:</strong>
            {{ saleInformation.CLAVE_CLIENTE.NOMBRE_EMPRESA }}<br />
            <strong>Fecha:</strong>
            {{ formatCreationDate(this.saleInformation.FECHA) }}<br />
            <strong>Dirección:</strong>
            {{ saleInformation.CLAVE_CLIENTE.DOMICIL_CLI }}
            {{ saleInformation.CLAVE_CLIENTE.ESTADO_CLI }}
            {{ saleInformation.CLAVE_CLIENTE.NOMBRE_MUNICIPIO }}
            {{ saleInformation.CLAVE_CLIENTE.CP_CLI }}<br />
          </td>
          <td style="padding: 20px; width: 50%">
            <strong>#{{ title }}:</strong>
            {{ saleInformation.CLAVE_VENTA }}<br />
            <strong>Email:</strong>
            {{
              saleInformation.CLAVE_CLIENTE.EMAI &&
              saleInformation.CLAVE_CLIENTE.EMAIL.length > 0
                ? saleInformation.CLAVE_CLIENTE.EMAIL[0]
                : "N/A"
            }}<br />
            <strong>Télefono:</strong>
            {{ saleInformation.CLAVE_CLIENTE.CEL_CLI || "N/A" }}<br />
          </td>
        </tr>
      </table>
      <br />
      <div class="table-overflow">
        <table
          width="100%"
          style="border-collapse: collapse; border-bottom: 1px solid #eee"
          v-if="!printWithoutPrices"
        >
          <tr>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              #
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Clave
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Servicio
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Costo
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Cantidad
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              IVA
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Porcentaje de retención de ISR
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Porcentaje de retención de IVA
            </td>
          </tr>
          <div
            class="display-content"
            v-for="(singleItem, index) of this.saleItems"
            :key="singleItem.RENGLON"
          >
            <tr class="article-element">
              <td class="row">{{ index + 1 }}</td>
              <td class="row">
                {{ singleItem.CLAVE_SERVICIO.CLAVE_SERVICIO }}
              </td>
              <td class="row">
                {{ singleItem.CLAVE_SERVICIO.DESCRIPCION }}
                <br />
                <span style="color: #777; font-size: 11px"></span>
              </td>
              <td class="row">{{ singleItem.COSTO_UNI | money("MXN", 2) }}</td>
              <td class="row">{{ singleItem.CANTIDAD }}</td>
              <td class="row">{{ singleItem.PORC_IVA }}%</td>
              <td class="row">{{ singleItem.PORC_RETENCION_ISR }}%</td>
              <td class="row">{{ singleItem.PORC_RETENCION_IVA }}%</td>
            </tr>
          </div>
        </table>
        <table
          width="100%"
          style="
            border-collapse: collapse;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          "
          v-if="printWithoutPrices"
        >
          <tr>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              #
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Clave
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Servicio
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Cantidad
            </td>
          </tr>
          <div
            class="display-content"
            v-for="(singleItem, index) of this.saleInformation.VENTA_DET"
            :key="singleItem.RENGLON"
          >
            <tr class="article-element">
              <td class="row">{{ index + 1 }}</td>
              <td class="row">
                {{ singleItem.CLAVE_SERVICIO.CLAVE_SERVICIO }}
              </td>
              <td class="row">
                {{ singleItem.CLAVE_SERVICIO.DESCRIPCION }}
                <br />
                <span style="color: #777; font-size: 11px"></span>
              </td>
              <td class="row">{{ singleItem.CANTIDAD }}</td>
            </tr>
          </div>
        </table>
        <br />
      </div>
      <table
        width="100%"
        style="padding: 20px; border-radius: 5px"
        v-if="!printWithoutPrices"
        class="not-cut-printing"
      >
        <tr>
          <td
            style="padding-top: 1rem; padding-bottom: 1rem; padding-right: 2%"
          >
            <table style="float: right">
              <tr>
                <td><strong>Sub-total:</strong></td>
                <td style="text-align: right">
                  {{ this.saleInformation.SUBTOTAL | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>IVA:</strong></td>
                <td style="text-align: right">
                  {{ this.saleInformation.IVA | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>Retención ISR:</strong></td>
                <td style="text-align: right">
                  {{ this.saleInformation.RETENCION_ISR_GTO | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>Retención IVA:</strong></td>
                <td style="text-align: right">
                  {{ this.saleInformation.RETENCION_IVA_GTO | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>Total:</strong></td>
                <td style="text-align: right">
                  {{ this.saleInformation.TOTAL | money("MXN", 2) }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <div
        class="alert not-cut-printing"
        style="border-radius: 5px"
        :style="backgroundStyle"
      >
        <strong :style="[textStyle]">Observaciones</strong> <br />
        <br />
        <b-input
          class="not-cut-printing"
          v-if="!printing"
          type="textarea"
          v-model="saleInformation.OBSERVA_IMP"
        ></b-input>
        <div
          class="observations-element observations-element-printing not-cut-printing"
          v-if="printing"
          style="background-color: white; padding: 0.625em; min-height: 120px"
        >
          <p>{{ saleInformation.OBSERVA_IMP }}</p>
        </div>
      </div>
      <p class="special-margin-top not-cut-printing">
        <strong>
          {{
            companyInformation.LEYENDA_INFERIOR
              ? companyInformation.LEYENDA_INFERIOR
              : ""
          }}</strong
        >
      </p>
      <div v-if="printing" style="margin-top: 1.5rem" class="not-cut-printing">
        Este pdf fue impreso por coconutcontrol.com
      </div>
    </div>
    <div class="container-print">
      <div class="add-global-controls">
        <b-button type="is-success" @click="print(0)"
          >Descargar sin precios</b-button
        >
        <b-button type="is-success" @click="print(1)"
          >Descargar con precios</b-button
        >

        <b-tooltip
          v-if="
            (!selectedEmail || clientSendEmails.length === 0) &&
            permissions.global &&
            permissions.global.sendEmailProfessionalServicesSale === 'S' &&
            sendClientEmail
          "
          label="Primero debes seleccionar un correo de donde enviar la venta y uno o más correos de destino"
          multilined
        >
          <b-button type="is-success" disabled>Enviar sin precios</b-button>
        </b-tooltip>

        <b-button
          type="is-success"
          @click="sendSaleQuote(0)"
          v-if="
            selectedEmail &&
            clientSendEmails.length > 0 &&
            permissions.global &&
            permissions.global.sendEmailProfessionalServicesSale === 'S' &&
            sendClientEmail
          "
          >Enviar sin precios</b-button
        >

        <b-tooltip
          v-if="
            (!selectedEmail || clientSendEmails.length === 0) &&
            permissions.global &&
            permissions.global.sendEmailProfessionalServicesSale === 'S' &&
            sendClientEmail
          "
          label="Primero debes seleccionar un correo de donde enviar la venta y uno o más correos de destino"
          multilined
        >
          <b-button type="is-success" disabled>Enviar con precios</b-button>
        </b-tooltip>

        <b-button
          type="is-success"
          @click="sendSaleQuote(1)"
          v-if="
            selectedEmail &&
            clientSendEmails.length > 0 &&
            permissions.global &&
            permissions.global.sendEmailProfessionalServicesSale === 'S' &&
            sendClientEmail
          "
          >Enviar con precios</b-button
        >

        <b-button type="is-danger" @click="closeModal()" class="cancel-button"
          >Regresar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import { openConfirmationModalSucces } from "../../../../utils/component-fns";
import { hexToRGB } from "../../../../utils/fns";

// @ is an alias to /src
export default {
  name: "PrintProsessionalServicesSales",
  props: ["saleInformation", "title"],
  components: {},
  data() {
    return {
      printing: false,
      printWithoutPrices: false,
      clientSendEmails: [],
      selectedEmail: "",
      sendClientEmail: true,
    };
  },
  mounted() {
    moment.locale("es");
    if (this.saleInformation.CLAVE_CLIENTE.EMAIL) {
      this.clientSendEmails = this.saleInformation.CLAVE_CLIENTE.EMAIL;
    }
    if (this.$store.getters.COMPANIES_EMAILS.length > 0) {
      this.selectedEmail = this.$store.getters.COMPANIES_EMAILS[0]._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc print the purchase order with the desire format
     */
    async print(type) {
      this.createPdfHtml(type, "print");
      setTimeout(() => {
        this.printWithoutPrices = false;
      }, 500);
    },
    /**
     * @desc send the purchase order to the provider emails
     */
    async sendSaleQuote(type) {
      if (
        this.permissions.global &&
        this.permissions.global.sendEmailProfessionalServicesSale === "S"
      ) {
        let pdfContent = await this.createPdfHtml(type, "email");
        let response = await this.$store.dispatch(
          "SENDPDFFILEPROFESSIONALSERVICESSALES",
          {
            clientEmails: this.clientSendEmails,
            selectedEmail: this.selectedEmail,
            fileName: `${this.title} ${this.saleInformation.CLAVE_VENTA} ${this.saleInformation.CLAVE_CLIENTE.NOMBRE_EMPRESA} ${this.companyName}.pdf`,
            pdfFile: pdfContent,
          }
        );
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se envió correctamente la venta",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al enviar la venta",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      }
    },
    /**
     *
     * @desc cretes or generates a pdf file
     */
    async createPdfHtml(type, action) {
      if (type === 0) {
        this.printWithoutPrices = true;
      } else {
        this.printWithoutPrices = false;
      }
      this.printing = true;
      let pdfOptions = {
        filename: `${this.title} ${this.saleInformation.CLAVE_VENTA} ${this.saleInformation.CLAVE_CLIENTE.NOMBRE_EMPRESA} ${this.companyName}.pdf`,
        pagebreak: {
          avoid: [
            ".article-element",
            ".observations-element",
            ".not-cut-printing",
          ],
        },
        scale: 2,
        image: { type: "jpeg", quality: 0.98 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        margin: [0, 0.2, 0, 0.2],
      };
      let result = await html2pdf()
        .set(pdfOptions)
        .from(this.$refs.saleQuote)
        .save()
        .output("datauristring");
      this.printing = false;
      return result;
    },
    formatCreationDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  computed: {
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    companyName() {
      return this.$store.getters.COMPANY_INFORMATION.name;
    },
    companyEmails() {
      return this.$store.getters.COMPANIES_EMAILS;
    },
    permissions() {
      return this.$store.getters.USER.permissions.permissions;
    },
    saleItems() {
      return this.saleInformation.VENTA_DET.map((singleItem) => {
        const singleItemWithProps = { ...singleItem };
        if (singleItem.CLAVE_ART) {
          if (
            singleItem.PRESENTACION === "UB" &&
            singleItem.CLAVE_ART.UNIDAD_EMP
          ) {
            singleItemWithProps.presentationName =
              singleItem.CLAVE_ART.UNIDAD_EMP.DESCRIP_MED;
          } else if (
            singleItem.PRESENTACION === "UB" &&
            !singleItem.CLAVE_ART.UNIDAD_EMP
          ) {
            singleItemWithProps.presentationName = "Unidad de medida base";
          } else {
            // Set presentation name
            for (const singlePresentation of singleItem.CLAVE_ART
              .PRESENTACIONES) {
              if (singlePresentation._id === singleItem.PRESENTACION) {
                singleItemWithProps.presentationName =
                  singlePresentation.NOMBRE;
              }
            }
          }
        }
        return singleItemWithProps;
      });
    },
    backgroundStyle() {
      return {
        "background-color": this.companyInformation.FONDO
          ? hexToRGB(this.companyInformation.FONDO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    backgroundSecondaryStyle() {
      return {
        "background-color": this.companyInformation.FONDO_SECUNDARIO
          ? hexToRGB(this.companyInformation.FONDO_SECUNDARIO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    textStyle() {
      return {
        color: this.companyInformation.TEXTO
          ? hexToRGB(this.companyInformation.TEXTO, 1)
          : "#363636",
      };
    },
    textSecondaryStyle() {
      return {
        color: this.companyInformation.TEXTO_SECUNDARIO
          ? hexToRGB(this.companyInformation.TEXTO_SECUNDARIO, 1)
          : "#363636",
      };
    },
  },
  watch: {
    purchaseOrderInformation() {
      if (this.purchaseOrderInformation.PRV.EMAIL) {
        this.clientSendEmails.push(this.purchaseOrderInformation.PRV.EMAIL);
      }
    },
    companyEmails() {
      this.selectedEmail = this.companyEmails[0]._id;
    },
  },
};
</script>

<style scoped>
@import "./style/PrintPurchaseOrder.css";
@import "../../style/Global.css";
</style>
