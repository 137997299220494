<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!clientInformation">
      Agregar cliente o prospecto
    </h1>

    <div v-if="!clientInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-switch
        v-model="newClient.SI_PUBGRAL"
        true-value="S"
        false-value="N"
        @input="changeGeneralPublic()"
      >
        Es público en general
      </b-switch>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Tipo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newClientRequiredErrors.TIPO }"
        :message="{
          'La clave no es valida': newClientRequiredErrors.TIPO,
        }"
      >
        <b-select
          placeholder="Ejemplo: Cliente"
          icon="account-tie"
          expanded
          v-model="newClient.TIPO"
          required
          class="required-input"
        >
          <option value="CL">Cliente</option>
          <option value="PR">Prospecto</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="RFC del cliente (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newClientRequiredErrors.RFC_CLI }"
        :message="{
          'La clave no es valida': newClientRequiredErrors.RFC_CLI,
        }"
      >
        <b-input
          placeholder="Ejemplo: CAPV841211G54"
          expanded
          icon="numeric"
          v-model="newClient.RFC_CLI"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave de uso del CFDI (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newClientRequiredErrors.CLAVE_USO_SAT }"
        :message="{
          'La clave de uso del CFDI no es valida':
            newClientRequiredErrors.CLAVE_USO_SAT,
        }"
      >
        <b-select
          placeholder="Ejemplo: Uso del CFDI"
          icon="domain"
          expanded
          v-model="newClient.CLAVE_USO_SAT"
          required
          class="required-input"
        >
          <option value="" disabled>Seleccione un uso del CFDI</option>
          <option
            v-for="singleCfdiUse in cfdiUses"
            :value="singleCfdiUse._id"
            :key="singleCfdiUse._id"
          >
            {{ singleCfdiUse.CVE_USO_SAT }} {{ singleCfdiUse.DESCRIPCION }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Régimen fiscal (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newClientRequiredErrors.CLAVE_REGIMEN }"
        :message="{
          'El régimen fical no es valido':
            newClientRequiredErrors.CLAVE_REGIMEN,
        }"
      >
        <b-select
          placeholder="Ejemplo: Régimen fiscal"
          icon="briefcase-outline"
          expanded
          v-model="newClient.CLAVE_REGIMEN"
          required
          class="required-input"
        >
          <option value="" disabled>Seleccione un régimen</option>
          <option
            v-for="singleRegimen in regimenes"
            :value="singleRegimen._id"
            :key="singleRegimen._id"
          >
            {{ singleRegimen.CVE_REG_SAT }} {{ singleRegimen.DESCRIPCION }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Giro (Opcional)" expanded label-position="inside">
        <b-select
          placeholder="Ejemplo: Giro"
          icon="domain"
          expanded
          v-model="newClient.GIRO_EMPRESA"
          @input="handleGiroInput"
        >
          <option :value="undefined" disabled>Seleccione un giro</option>
          <option
            v-for="singleCommercialBusiness in commercialBusiness"
            :value="singleCommercialBusiness._id"
            :key="singleCommercialBusiness._id"
          >
            {{ singleCommercialBusiness.DESCRIPCION_GIR }}
          </option>
          <option value="addNewItem">+ Agregar un giro</option>
        </b-select>
      </b-field>
      <b-field
        label="Número de registro de identidad fiscal (Opcional)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newClientRequiredErrors.NUM_IDENFISCAL }"
        :message="{
          'El número de registro no es valido':
            newClientRequiredErrors.NUM_IDENFISCAL,
        }"
      >
        <b-input
          placeholder="Ejemplo: Número de registro de identidad fiscal"
          expanded
          icon="briefcase-search-outline"
          v-model="newClient.NUM_IDENFISCAL"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Razón social sin tipo de sociedad (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newClientRequiredErrors.NOMBRE_EMPRESA }"
        :message="{
          'La razón social no es valida':
            newClientRequiredErrors.NOMBRE_EMPRESA,
        }"
      >
        <b-input
          placeholder="Ejemplo: Empresa de muestra"
          expanded
          icon="clipboard"
          v-model="newClient.NOMBRE_EMPRESA"
          @input="
            (val) =>
              (newClient.NOMBRE_EMPRESA =
                newClient.NOMBRE_EMPRESA.toUpperCase())
          "
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="URL (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: http://coconutcontrol.com"
          expanded
          icon="numeric"
          v-model="newClient.URL"
        ></b-input>
      </b-field>
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Observación de muestra"
          expanded
          icon="numeric"
          v-model="newClient.OBSERVACIONES"
        ></b-input>
      </b-field>
    </b-field>

    <b-field
      label="Almacen (Opcional)"
      expanded
      label-position="inside"
      class="special-margin"
    >
      <b-select
        placeholder="Ejemplo: General"
        expanded
        icon="warehouse"
        v-model="newClient.CLAVE_ALMACEN"
      >
        <option value="" disabled>Seleccione un almacén</option>
        <option
          v-for="singleWarehouse in warehouses"
          :value="singleWarehouse._id"
          :key="singleWarehouse._id"
        >
          {{ singleWarehouse.NOMBRE_ALM }}
        </option>
      </b-select>
    </b-field>

    <b-field
      label="Cuentas predial (Opcional)"
      expanded
      label-position="inside"
      class="special-margin"
    >
      <b-taginput
        v-model="newClient.CUENTAS_PREDIAL"
        ellipsis
        icon="home-group"
        placeholder="Añada una cuenta predial"
        aria-close-label="Borrar cuentas predial"
        type="is-primary"
      >
      </b-taginput>
    </b-field>

    <h3 class="form-division">Dirección</h3>

    <b-field grouped class="special-margin">
      <b-field expanded label-position="inside">
        <template slot="label">
          Código Postal (Opcional)
          <b-tooltip
            multilined
            label="Al llenar el código postal la información de estado, municipio, ciudad y colonia es autocompletada"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: 00000"
          expanded
          icon="directions-fork"
          v-model="newClient.CP_CLI"
          v-cleave="masks.customCP"
          @input="(value) => searchForDirectionInformation(value)"
          type="number"
          step="any"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Dirección (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Paseo del árbol #229"
          expanded
          icon="file-outline"
          v-model="newClient.DOMICIL_CLI"
        ></b-input>
      </b-field>
      <b-field label="Referencia (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Casa azul"
          expanded
          icon="file-outline"
          v-model="newClient.REFERENCIA_CLI"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Número exterior (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 250"
          expanded
          icon="counter"
          v-model="newClient.NUM_EXTER"
        ></b-input>
      </b-field>
      <b-field
        label="Número interior (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 10"
          expanded
          icon="counter"
          v-model="newClient.NUM_INTER"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Municipio (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Nuevo León"
          expanded
          icon="sign-real-estate"
          v-model="newClient.NOMBRE_MUNICIPIO"
        ></b-input>
      </b-field>
      <b-field
        label="Clave de municipio (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 001"
          expanded
          icon="city-variant-outline"
          v-model="newClient.MUNICIPIO_CLI"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Estado (Opcional)" expanded label-position="inside">
        <b-select
          placeholder="Ejemplo: Nuevo León"
          expanded
          icon="sign-real-estate"
          v-model="newClient.ESTADO_CLI"
        >
          <option value="" disabled>Seleccione un estado</option>
          <option
            v-for="singleState in states"
            :value="singleState.CLAVE"
            :key="singleState.NOMBRE"
          >
            {{ singleState.NOMBRE }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Clave de la localidad (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 01"
          expanded
          icon="sign-real-estate"
          v-model="newClient.LOCALIDAD"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field expanded label-position="inside">
        <template slot="label">
          Nombre de la colonia (Opcional)
          <b-tooltip
            multilined
            label="El listado de colonias disponibles aparece una vez llenado el código postal"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-autocomplete
          :data="filteredColonies"
          field="colony"
          open-on-focus
          placeholder="Ejemplo: Del Norte"
          expanded
          icon="home-group"
          v-model="newClient.NOMBRE_COLONIA"
          clearable
          @select="(option) => (newClient.NOMBRE_COLONIA = option.colony)"
        >
          <template #empty>No se encontraron colonias</template>
        </b-autocomplete>
      </b-field>

      <b-field
        label="Clave de la colonia (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 0001"
          expanded
          icon="sign-real-estate"
          v-model="newClient.COLONIA_CLI"
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division" v-if="creditActive || !clientInformation">
      Información de ventas
    </h3>

    <b-switch
      v-model="creditActive"
      class="special-margin"
      v-if="
        companyPaymentPlanModules.sales.editClientCredit === 'S' &&
        permissions.sales.editClientCredit === 'S'
      "
    >
      El cliente tiene crédito
    </b-switch>

    <b-field
      grouped
      class="special-margin"
      v-if="
        companyPaymentPlanModules.sales.editClientLists === 'S' &&
        permissions.sales.editClientLists === 'S'
      "
    >
      <b-field label="Referencia (Opcional)" expanded label-position="inside">
        <template slot="label">
          Referencia (Opcional)
          <b-tooltip
            multilined
            label="Cómo se entero el cliente sobre la empresa"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: Vendedor de planta"
          expanded
          icon="account-group-outline"
          v-model="newClient.REFERENCIA_CLI"
        ></b-input>
      </b-field>
    </b-field>

    <b-field
      grouped
      class="special-margin"
      v-if="
        companyPaymentPlanModules.sales.editClientLists === 'S' &&
        permissions.sales.editClientLists === 'S'
      "
    >
      <b-field
        label-position="inside"
        expanded
        label="Vendedor asignado (Opcional)"
      >
        <b-select
          placeholder="Ejemplo: Sin vendedor asignado"
          icon="account-tie"
          expanded
          v-model="newClient.CLAVE_VENDEDOR"
        >
          <option :value="undefined">Sin vendedor asignado</option>
          <option
            :value="singleEmployee._id"
            v-for="singleEmployee in employees"
            :key="singleEmployee._id"
          >
            {{ singleEmployee.NOMBRE }} {{ " " }} {{ singleEmployee.PATERNO }}
            {{ " " }}
            {{ singleEmployee.MATERNO }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field
      grouped
      class="special-margin"
      v-if="
        companyPaymentPlanModules.sales.editClientLists === 'S' &&
        permissions.sales.editClientLists === 'S'
      "
    >
      <b-field
        label-position="inside"
        expanded
        label="Lista de precios predeterminada del cliente (Opcional)"
      >
        <b-select
          placeholder="Ejemplo: General"
          icon="clipboard-outline"
          expanded
          v-model="newClient.LISTA_PRECIOS_PRE"
          @input="handlePricesList"
        >
          <option value="" disabled>Seleccione una lista de precios</option>
          <option
            :value="singlePriceList._id"
            v-for="singlePriceList in priceLists"
            :key="singlePriceList._id"
          >
            {{ singlePriceList.CLAVE_LISTAP }}
          </option>
          <option value="addNewItem">+ Agregar una lista de precios</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field
      grouped
      class="special-margin"
      v-if="
        companyPaymentPlanModules.sales.editClientLists === 'S' &&
        permissions.sales.editClientLists === 'S'
      "
    >
      <b-field label-position="inside" class="full-width-div">
        <template slot="label">
          Listas de precios del cliente (Opcional)
          <b-tooltip
            multilined
            label="Listas de precios disponibles para este cliente"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-taginput
          :data="autoCompletePricesList()"
          v-model="newClient.LISTAS_PRECIOS"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="CLAVE_LISTAP"
          icon="clipboard-multiple-outline"
          placeholder="Ejemplo: General"
          :check-infinite-scroll="false"
        >
          <template #empty>
            <div class="has-text-centered">No hay listas de precios</div>
          </template>
        </b-taginput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin" v-if="creditActive">
      <b-field
        label="Diás de crédito (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 15"
          expanded
          type="number"
          step="any"
          icon="numeric"
          v-model="newClient.D_CREDITO"
        ></b-input>
      </b-field>
      <b-field
        label="Limite de crédito (Opcional)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="2"
          placeholder="Ejemplo: 10000"
          icon="clipboard"
          :required="false"
          v-model="newClient.LIMITE_CRED"
          :disabled="false"
        />
      </b-field>
    </b-field>

    <!--
    <b-field
      grouped
      class="special-margin"
      v-if="
        creditActive &&
        companyPaymentPlanModules.sales.editClientCredit === 'S' &&
        permissions.sales.editClientCredit === 'S'
      "
    >
      <b-field
        label="Porcentaje de interes (Opcional)"
        expanded
        label-position="inside"
      >
        <PercentageInput
          placeholder="Ejemplo: 10"
          expanded
          type="number"
          icon="chart-line-variant"
          v-model="POR_INTERES"
        />
      </b-field>
    </b-field>
    -->

    <h3 class="form-division">Información de contacto</h3>

    <b-field grouped class="special-margin">
      <b-field label="Nombre (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Pedro"
          expanded
          icon="account-box-outline"
          v-model="newClient.NOMBRE"
        ></b-input>
      </b-field>
      <!--
      <b-field
        label="Nombre abreviado (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pedro"
          expanded
          icon="account-multiple-outline"
          v-model="newClient.NOMB_ABREV"
        ></b-input>
      </b-field>
      -->
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Apellido Paterno (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Rodriguez"
          expanded
          icon="account-multiple-outline"
          v-model="newClient.PATER"
        ></b-input>
      </b-field>
      <b-field
        label="Apellido Materno (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pérez"
          expanded
          icon="account-child"
          v-model="newClient.MATER"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Teléfono (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: 3300000000"
          expanded
          icon="phone"
          v-model="newClient.TELS_CLI"
          v-cleave="masks.customPhone"
        ></b-input>
      </b-field>
      <b-field
        label="Teléfono celular (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 3300000000"
          expanded
          icon="cellphone"
          v-model="newClient.CEL_CLI"
          v-cleave="masks.customPhone"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Email (Opcional)" expanded label-position="inside">
        <b-taginput
          placeholder="Ejemplo: compañia@mail.com"
          expanded
          v-model="newClient.EMAIL"
          ellipsis
          icon="label"
          :before-adding="(tagToAdd) => validateEmail(tagToAdd)"
        >
        </b-taginput>
      </b-field>
    </b-field>

    <h3 class="form-division">Información de cobro</h3>

    <b-field grouped class="special-margin">
      <b-field expanded label-position="inside">
        <template slot="label">
          Encargado de cobranza (Opcional)
          <b-tooltip multilined label="Encargado de cobranza de este cliente">
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: compañia@mail.com"
          expanded
          icon="credit-card-outline"
          v-model="newClient.COBRANZA"
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Información de pago en ventas</h3>

    <b-field grouped class="special-margin">
      <b-field
        label-position="inside"
        label="Condición de pago (Opcional)"
        expanded
      >
        <b-select
          v-model="newClient.CONDICION_P"
          placeholder="Condición de pago"
          icon="credit-card-settings-outline"
          expanded
          @input="handlePaymentConditionChange()"
        >
          <option value="" disabled>Seleccione una condición de pago</option>
          <option value="C">Contado</option>
          <option value="R">Crédito</option>
        </b-select>
      </b-field>
      <b-field
        label-position="inside"
        label="Método de pago (Opcional)"
        expanded
      >
        <b-select
          placeholder="Método de pago"
          icon="numeric"
          expanded
          v-model="newClient.METODO_P"
          @input="handlePaymentMethodChange()"
        >
          <option value="" disabled>Seleccione un método de pago</option>
          <option value="P">Pago en una sola exhibición (PUE)</option>
          <option value="D">Pago en parcialidades o diferido (PPD)</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        class="invoice-add-secondary-item-four"
        label="Forma de pago (Opcional)"
        expanded
        label-position="inside"
      >
        <b-select
          placeholder="Ejemplo: Forma de pago"
          icon="credit-card-multiple-outline"
          expanded
          v-model="newClient.FORMA_P"
        >
          <option value="" disabled>Selecciones una forma de pago</option>
          <option
            :value="singlePaymentType._id"
            v-for="singlePaymentType in paymentTypes"
            v-bind:key="singlePaymentType._id"
          >
            {{ singlePaymentType.DESCRIBE_P }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-switch v-model="newClient.ENVIAR_ESTADO_CUENTA" class="special-margin">
      Enviar estado de cuenta automaticamente
    </b-switch>

    <div class="add-global-controls" v-if="clientInformation">
      <b-button type="is-success" @click="saveClient(1)">
        Actualizar cliente o prospecto
      </b-button>
    </div>

    <div class="add-global-controls" v-if="!clientInformation">
      <b-button type="is-success" @click="saveClient(0)"
        >Crear cliente o prospecto</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import AddCommercialBusiness from "./AddCommercialBusiness";
import AddPricesListModal from "./AddPricesListModal";
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import Cleave from "cleave.js";
import { fillObjectForm } from "../../../utils/component-fns";
import Masks from "../../../data/cleave-masks";
import States from "../../../data/states";
import {
  cfdiUsePorDefinir,
  regimenSinEfecto,
} from "../../../utils/env-variables-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "AddClient",
  props: ["clientInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      newClient: {
        TIPO: "CL",
        NOMBRE_EMPRESA: "",
        TIPOPERSON: "FI",
        SI_PUBGRAL: "N",
        D_CREDITO: "",
        LIMITE_CRED: "",
        SALDO: 0,
        OBSERVACIONES: "",
        URL: "",
        DOMICIL_CLI: "",
        NUM_EXTER: "",
        NUM_INTER: "",
        REFERENCIA_CLI: "",
        CP_CLI: "",
        NOMBRE_COLONIA: "",
        COLONIA_CLI: "",
        ESTADO_CLI: "",
        LOCALIDAD: "",
        NOMBRE_MUNICIPIO: "",
        MUNICIPIO_CLI: "",
        TELS_CLI: "",
        CEL_CLI: "",
        EMAIL: [],
        PATER: "",
        MATER: "",
        NOMBRE: "",
        REFERENCIA: "",
        CLAVE_ALMACEN: "",
        COBRANZA: "",
        CLAVE_USO_SAT: "",
        CLAVE_REGIMEN: "",
        GIRO_EMPRESA: undefined,
        NUM_IDENFISCAL: "",
        LISTA_PRECIOS_PRE: undefined,
        LISTAS_PRECIOS: [],
        RFC_CLI: "",
        POR_INTERES: 0,
        CLAVE_VENDEDOR: undefined,
        CUENTAS_PREDIAL: [],
        CONDICION_P: "",
        METODO_P: "",
        FORMA_P: undefined,
        ENVIAR_ESTADO_CUENTA: false,
      },
      newClientRequiredErrors: {
        TIPO: false,
        NOMBRE_EMPRESA: false,
        TIPOPERSON: false,
        SI_PUBGRAL: false,
        RFC_CLI: false,
        CLAVE_USO_SAT: false,
        CLAVE_REGIMEN: false,
      },
      currentColonies: [],
      masks: Masks,
      states: States,
      creditActive: false,
      priceListName: [],
      cfdiUsePorDefinir: cfdiUsePorDefinir,
      regimenSinEfecto: regimenSinEfecto,
    };
  },
  async mounted() {
    // Fill the prices list information in form fields
    if (this.clientInformation) {
      // Fill the form with received information
      fillObjectForm(this.clientInformation, this.newClient);
      this.newClient._id = this.clientInformation._id;

      if (this.clientInformation.LISTA_PRECIOS_PRE) {
        this.newClient.LISTA_PRECIOS_PRE =
          this.clientInformation.LISTA_PRECIOS_PRE._id;
      }

      if (this.clientInformation.CLAVE_VENDEDOR) {
        for (const singleEmployee of this.employees) {
          if (
            singleEmployee._id.toString() ===
            this.clientInformation.CLAVE_VENDEDOR.toString()
          ) {
            this.newClient.CLAVE_VENDEDOR = singleEmployee._id;
          }
        }
      }

      if (this.clientInformation.FORMA_P) {
        this.newClient.FORMA_P = this.clientInformation.FORMA_P;
      }

      if (this.clientInformation.GIRO_EMPRESA) {
        this.newClient.GIRO_EMPRESA = this.clientInformation.GIRO_EMPRESA;
      }

      if (this.clientInformation.LIMITE_CRED) {
        this.creditActive = true;
      }
    }

    if (this.newClient.CP_CLI.length === 5) {
      let response = await this.$store.dispatch("SEARCHDIRECTIONINFORMATION", {
        zipCode: this.newClient.CP_CLI,
      });
      if (response.length > 0) {
        this.currentColonies = response;
      }
    }

    if (!this.clientInformation) {
      if (this.companyInformation.LISTA_PRECIOS_PRE) {
        for (const singlePriceList of this.priceLists) {
          if (
            singlePriceList._id.toString() ===
            this.companyInformation.LISTA_PRECIOS_PRE.toString()
          ) {
            this.newClient.LISTA_PRECIOS_PRE = singlePriceList._id;
          }
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates a client depending on type
     */
    async saveClient(type) {
      try {
        this.newClientRequiredErrors = validateFields(
          this.newClient,
          this.newClientRequiredErrors
        );
        if (checkValidationErrors(this.newClientRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVECLIENT" : "EDITCLIENT",
            this.newClient
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el cliente o prospecto",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (!this.clientInformation) {
                  this.closeModal();
                }
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: `Hubo un error al guardar el cliente o prospecto en la base de datos: ${response}`,
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el cliente o prospecto en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el cliente o prospecto en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newClient = {
        TIPO: "CL",
        NOMBRE_EMPRESA: "",
        TIPOPERSON: "FI",
        SI_PUBGRAL: "N",
        D_CREDITO: "",
        LIMITE_CRED: "",
        SALDO: 0,
        OBSERVACIONES: "",
        URL: "",
        DOMICIL_CLI: "",
        NUM_EXTER: "",
        NUM_INTER: "",
        REFERENCIA_CLI: "",
        CP_CLI: "",
        NOMBRE_COLONIA: "",
        COLONIA_CLI: "",
        ESTADO_CLI: "",
        LOCALIDAD: "",
        NOMBRE_MUNICIPIO: "",
        MUNICIPIO_CLI: "",
        TELS_CLI: "",
        CEL_CLI: "",
        PATER: "",
        MATER: "",
        NOMBRE: "",
        REFERENCIA: "",
        CLAVE_ALMACEN: "",
        COBRANZA: "",
        CLAVE_USO_SAT: "",
        CLAVE_REGIMEN: "",
        GIRO_EMPRESA: "",
        NUM_IDENFISCAL: "",
        LISTA_PRECIOS_PRE: undefined,
        RFC_CLI: "",
        POR_INTERES: 0,
        CUENTAS_PREDIAL: [],
        ENVIAR_ESTADO_CUENTA: false,
      };
    },
    /**
     * @desc searches for direction information when zip has the corret format
     */
    async searchForDirectionInformation(zipCode) {
      if (zipCode.length === 5) {
        let response = await this.$store.dispatch(
          "SEARCHDIRECTIONINFORMATION",
          { zipCode }
        );
        if (response.length > 0) {
          this.newClient.ESTADO_CLI = response[0].state_code;
          this.newClient.NOMBRE_MUNICIPIO = response[0].municipality;
          this.newClient.MUNICIPIO_CLI = response[0].municipio_code;
          this.newClient.LOCALIDAD = response[0].localidad_code;
          this.newClient.NOMBRE_COLONIA = response[0].colony;
          this.newClient.COLONIA_CLI = response[0].colony_code;
          this.currentColonies = response;
        }
      }
    },
    openAddCommercialBusiness() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCommercialBusiness,
        props: {
          type: 0,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    handleGiroInput() {
      if (this.newClient.GIRO_EMPRESA === "addNewItem") {
        this.openAddCommercialBusiness();
        setTimeout(() => {
          this.newClient.GIRO_EMPRESA = "";
        }, 500);
      }
    },
    openAddPriceLists() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPricesListModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    handlePricesList() {
      if (this.newClient.LISTA_PRECIOS_PRE === "addNewItem") {
        this.openAddPriceLists();
        setTimeout(() => {
          this.newClient.LISTA_PRECIOS_PRE = "";
        }, 500);
      }
    },
    autoCompletePricesList() {
      if (!this.priceLists) {
        return [];
      }
      return this.priceLists;
    },
    checkValidCredit() {
      if (this.creditActive) {
        return this.newClient.D_CREDITO > 0;
      }

      return true;
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    changeGeneralPublic() {
      if (this.newClient.SI_PUBGRAL === "S") {
        this.newClient.RFC_CLI = "XAXX010101000";
        this.newClient.CLAVE_REGIMEN = regimenSinEfecto;
        this.newClient.CLAVE_USO_SAT = cfdiUsePorDefinir;
        if (this.companyInformation.CP) {
          this.newClient.CP_CLI = this.companyInformation.CP;
          this.searchForDirectionInformation(this.companyInformation.CP);
        }
      } else {
        this.newClient.RFC_CLI = "";
        this.newClient.CLAVE_REGIMEN = "";
        this.newClient.CLAVE_USO_SAT = "";
        this.newClient.CP_CLI = "";
      }
    },
    handlePaymentConditionChange() {
      if (this.newClient.CONDICION_P === "C") {
        this.newClient.METODO_P = "P";
      }
    },
    handlePaymentMethodChange() {
      if (this.newClient.METODO_P === "D") {
        this.newClient.CONDICION_P = "R";
      }
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        const singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      });
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        singlePriceListWithProps.formatedUtility =
          singlePriceListWithProps.PORC_UTILID + "%";
        return singlePriceListWithProps;
      });
    },
    rfcValue() {
      return this.newClient.RFC_CLI;
    },
    regimenes() {
      return this.$store.getters.SATREGIMENES.map((singleRegimen) => {
        const regimenWithProps = { ...singleRegimen };
        if (regimenWithProps.TIPO_R === "A") {
          regimenWithProps.typeFormatted = "Ambos";
        } else if (regimenWithProps.TIPO_R === "F") {
          regimenWithProps.typeFormatted = "Persona física";
        } else if (regimenWithProps.TIPO_R === "M") {
          regimenWithProps.typeFormatted = "Persona moral";
        }
        return regimenWithProps;
      }).filter((singleRegimen) => {
        if (this.rfcValue.length === 13) {
          return singleRegimen.TIPO_R === "A" || singleRegimen.TIPO_R === "F";
        } else if (this.rfcValue.length === 12) {
          return singleRegimen.TIPO_R === "A" || singleRegimen.TIPO_R === "M";
        } else {
          return singleRegimen.TIPO_R === "A";
        }
      });
    },
    cfdiUses() {
      return this.$store.getters.SATCFDIUSES.map((singleUse) => {
        const useWithProps = { ...singleUse };
        if (useWithProps.TIPO_P === "A") {
          useWithProps.typeFormatted = "Ambos";
        } else if (useWithProps.TIPO_P === "F") {
          useWithProps.typeFormatted = "Persona física";
        } else if (useWithProps.TIPO_P === "M") {
          useWithProps.typeFormatted = "Persona moral";
        }
        return useWithProps;
      });
    },
    commercialBusiness() {
      return this.$store.getters.COMMERCIALBUSINESS.map(
        (singleCommercialBusiness) => {
          const commercialBusinessWithProps = { ...singleCommercialBusiness };
          if (commercialBusinessWithProps.TIPO === "C") {
            commercialBusinessWithProps.typeFormatted = "Clientes";
          } else if (commercialBusinessWithProps.TIPO === "P") {
            commercialBusinessWithProps.typeFormatted = "Proveedores";
          }
          return commercialBusinessWithProps;
        }
      ).filter((singleCommercialBusiness) => {
        return singleCommercialBusiness.TIPO === "C";
      });
    },
    filteredColonies() {
      return this.currentColonies.filter((singleColony) => {
        return (
          singleColony.colony
            .toString()
            .toLowerCase()
            .indexOf(this.newClient.NOMBRE_COLONIA.toLowerCase()) >= 0
        );
      });
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "VE" || singleEmployee.TIPO === "VC";
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    paymentTypes() {
      return this.$store.getters.PAYMENTTYPES;
    },
  },
  watch: {
    priceLists() {
      if (!this.clientInformation) {
        if (this.companyInformation.LISTA_PRECIOS_PRE) {
          for (const singlePriceList of this.priceLists) {
            if (
              singlePriceList._id.toString() ===
              this.companyInformation.LISTA_PRECIOS_PRE.toString()
            ) {
              this.newClient.LISTA_PRECIOS_PRE = singlePriceList._id;
            }
          }
        }
      }
    },
    employees() {
      if (this.clientInformation && this.clientInformation.CLAVE_VENDEDOR) {
        for (const singleEmployee of this.employees) {
          if (
            singleEmployee._id.toString() ===
            this.clientInformation.CLAVE_VENDEDOR.toString()
          ) {
            this.newClient.CLAVE_VENDEDOR = singleEmployee._id;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
