var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_c('b-field',{staticClass:"global-lists-controls",attrs:{"grouped":"","group-multiline":""}},[_c('div',{staticClass:"control special-margin"},[(
          _vm.companyPaymentPlanModules &&
          _vm.companyPaymentPlanModules.main.importArticles === 'S' &&
          _vm.permissions &&
          _vm.permissions.main.importArticles === 'S'
        )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.startImport()}}},[_vm._v("Importar archivo de entrenamiento")]):_vm._e()],1),_c('div',{staticClass:"control special-margin"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.trainModelWithSales()}}},[_vm._v("Entrenar modelo con ventas de últimos dos años")])],1),_c('div',{staticClass:"control special-margin"},[_c('b-button',{attrs:{"type":"is-primary","loading":_vm.refreshLoading},on:{"click":function($event){return _vm.reloadInformation()}}},[_vm._v("Refrescar información")])],1)]),_c('div',{staticClass:"global-lists-search"},[_c('div',{staticClass:"global-lists-filters-container"}),_c('div',{staticClass:"global-lists-filters-columns"},_vm._l((_vm.columns),function(singleColumn){return _c('b-checkbox',{key:singleColumn.label,model:{value:(singleColumn.display),callback:function ($$v) {_vm.$set(singleColumn, "display", $$v)},expression:"singleColumn.display"}},[_vm._v(" "+_vm._s(singleColumn.label)+" ")])}),1)]),_c('div',{staticClass:"table-header"},[_vm._v(" Cantidad de Artículos: "),_c('b',[_vm._v(_vm._s(_vm.articles.length))])]),_c('b-table',{ref:"Articles",attrs:{"pagination-position":"both","paginated":"","total":_vm.articles.length,"per-page":_vm.perPage,"loading":_vm.loading,"data":_vm.articles,"striped":true,"bordered":true,"hoverable":true,"checked-rows":_vm.checkedArticles,"current-page":_vm.currentPage,"selected":_vm.selected,"opened-detailed":_vm.defaultOpenedDetails,"detailed":"","detail-key":"_id","checkbox-position":"left","sort-icon":"arrow-up","default-sort-direction":"asc","default-sort":"CLAVE_ART"},on:{"update:checkedRows":function($event){_vm.checkedArticles=$event},"update:checked-rows":function($event){_vm.checkedArticles=$event},"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
          message: ("Información de " + (row.CLAVE_ART)),
          type: 'is-success',
          position: 'is-bottom',
        }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No hay articulos")])]},proxy:true},{key:"default",fn:function(props){return [_vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":column.searchable,"sortable":column.sortable,"visible":column.display,"label":column.label,"custom-sort":column.customSort}},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('p',{on:{"click":function($event){return _vm.openPage(column.label)}}},[_vm._v(" "+_vm._s("Sin información")+" ")]):_vm._e(),_c('p',{on:{"click":function($event){return _vm.openPage(column.label)}}},[_vm._v(_vm._s(props.row[column.field]))])])}),_c('b-table-column',{attrs:{"label":"Modelo entrenado","field":"trained","sortable":"","searchable":""}},[(!props.row.trained)?_c('span',[_vm._v("Modelo aun no entrenado")]):_vm._e(),(props.row.trained)?_c('span',[_vm._v("Modelo entrenado")]):_vm._e()])]}},{key:"detail",fn:function(props){return [_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[(props.row.trained)?_c('div',[_c('SingleArticlePrediction',{attrs:{"articleInformation":props.row}})],1):_vm._e(),(!props.row.trained)?_c('div',[_c('p',[_vm._v("Modelo aun no entrenado")])]):_vm._e()])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }