var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_c('h3',{staticClass:"form-division"},[_vm._v("Predicción diaria usando machine learning")]),_c('apexchart',{key:_vm.predictionChartKey,attrs:{"type":"area","height":"350","options":_vm.predictionChart.chartOptions,"series":_vm.predictionChart.series}}),_c('h3',{staticClass:"form-division special-margin"},[_vm._v(" Predicción mensual usando análisis estadistico ")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":_vm.currentMonthName,"expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"disabled":"","placeholder":"Ejemplo: 100","expanded":"","value":_vm.currentMonthPrediction
            ? _vm.currentMonthPrediction
            : 'Sin información necesaria realizar predicción'}})],1),_c('b-field',{attrs:{"label":_vm.nextMonthName,"expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"disabled":"","placeholder":"Ejemplo: 100","expanded":"","value":_vm.nextMonthPrediction
            ? _vm.nextMonthPrediction
            : 'Sin información necesaria realizar predicción'}})],1)],1),_c('h3',{staticClass:"form-division special-margin"},[_vm._v(" Predicción mensual usando machine learning ")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":_vm.currentMonthMachineLearningPrediction,"expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"disabled":"","placeholder":"Ejemplo: 100","expanded":"","value":_vm.currentMonthMachineLearningPrediction
            ? _vm.currentMonthMachineLearningPrediction
            : 'Sin información necesaria realizar predicción'}})],1),_c('b-field',{attrs:{"label":_vm.nextMonthMachineLearningPrediction,"expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"disabled":"","placeholder":"Ejemplo: 100","expanded":"","value":_vm.nextMonthMachineLearningPrediction
            ? _vm.nextMonthMachineLearningPrediction
            : 'Sin información necesaria realizar predicción'}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }