<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title print-hide">{{ title }}</h1>

    <div class="container-print" ref="saleQuote">
      <img
        v-if="companyInformation.LOGO_URL"
        :src="companyInformation.LOGO_URL"
        alt="Company logo"
        style="margin-top: 1rem; width: 20%; margin-bottom: 1rem"
      />
      <p class="special-margin">
        <strong>
          {{
            companyInformation.LEYENDA_SUPERIOR
              ? companyInformation.LEYENDA_SUPERIOR
              : ""
          }}</strong
        >
      </p>
      <table width="100%">
        <tr class="logotype-container">
          <td>
            <div class="logotype-type" :style="[backgroundStyle, textStyle]">
              {{ title }}
            </div>
          </td>
        </tr>
      </table>
      <br />
      <div style="margin-bottom: 20px">
        <strong>Información de la empresa</strong><br />
      </div>
      <table width="100%">
        <tr>
          <td>
            <table>
              <tr>
                <td>
                  <strong>Nombre:</strong>
                  {{ companyName }} <br />
                  <strong>Dirección:</strong>
                  {{ companyInformation.DOMICIL }}
                  {{ companyInformation.NOMBRE_MUNICIPIO }},
                  {{ companyInformation.ESTADO }}
                  {{ companyInformation.CP }}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <br />
      <div
        style="padding-left: 20px; padding-top: 10px; padding-bottom: 10px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Información del cliente</strong><br />
      </div>
      <table width="100%">
        <tr>
          <td style="padding: 20px; width: 50%">
            <strong>Número:</strong>
            {{ saleInformation.CLAVE_CLIENTE.CLAVE_CLI }}<br />
            <strong>Nombre:</strong>
            {{ saleInformation.CLAVE_CLIENTE.NOMBRE_EMPRESA }}<br />
            <strong>Fecha:</strong>
            {{ formatCreationDate(saleInformation.FECHA) }}<br />
            <strong>Dirección:</strong>
            {{ saleInformation.CLAVE_CLIENTE.DOMICIL_CLI }}
            {{ saleInformation.CLAVE_CLIENTE.ESTADO_CLI }}
            {{ saleInformation.CLAVE_CLIENTE.NOMBRE_MUNICIPIO }}
            {{ saleInformation.CLAVE_CLIENTE.CP_CLI }}<br />
          </td>
          <td style="padding: 20px; width: 50%">
            <strong>#{{ title }}:</strong>
            {{ saleInformation.CLAVE_VENTA }}<br />
            <strong>Email:</strong>
            {{
              saleInformation.CLAVE_CLIENTE.EMAIL &&
              saleInformation.CLAVE_CLIENTE.EMAIL.length > 0
                ? saleInformation.CLAVE_CLIENTE.EMAIL[0]
                : "N/A"
            }}<br />
            <strong>Télefono:</strong>
            {{ saleInformation.CLAVE_CLIENTE.CEL_CLI || "N/A" }}<br />
          </td>
        </tr>
      </table>
      <br />
      <div class="table-overflow">
        <table
          width="100%"
          style="
            border-collapse: collapse;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          "
        >
          <tr>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              #
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Clave
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Descripción
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Cantidad
            </td>
          </tr>
          <div
            class="display-content"
            v-for="(singleItem, index) of deliveryDetails"
            :key="singleItem.RENGLON"
          >
            <tr class="article-element">
              <td class="row">{{ index + 1 }}</td>
              <td class="row">
                {{ singleItem.CONCEP_IE }}
              </td>
              <td class="row">
                {{
                  singleItem.PRESENTACION === "UB"
                    ? singleItem.DESCRIBEAR
                    : singleItem.presentationName
                }}
              </td>
              <td class="row">{{ singleItem.CANTIDAD }}</td>
            </tr>
          </div>
        </table>
        <br />
      </div>
      <div
        class="alert not-cut-printing"
        style="border-radius: 5px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Observaciones del chofer: </strong> <br />
        <br />
        <b-input
          v-if="!printing"
          type="textarea"
          v-model="deliveryInormation.OBSERVA"
        ></b-input>
        <div
          class="observations-element observations-element-printing not-cut-printing"
          v-if="printing"
          style="background-color: white; padding: 0.625em; min-height: 120px"
        >
          <p>{{ deliveryInormation.OBSERVA }}</p>
        </div>
        <div style="margin-top: 60px" class="vue-signature-container">
          <VueSignaturePad
            height="14rem"
            ref="signature"
            :customStyle="{ background: 'white' }"
          />
          <strong :style="textStyle">
            <br />
            Firma de recibido y completo del chofer
          </strong>
        </div>
      </div>
      <div
        class="alert not-cut-printing"
        style="border-radius: 5px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Observaciones del cliente: </strong> <br />
        <br />
        <b-input
          v-if="!printing"
          type="textarea"
          v-model="deliveryInormation.OBSERVA_CLI"
        ></b-input>
        <div
          class="observations-element observations-element-printing not-cut-printing"
          v-if="printing"
          style="background-color: white; padding: 0.625em; min-height: 120px"
        >
          <p>{{ deliveryInormation.OBSERVA_CLI }}</p>
        </div>
        <div style="margin-top: 60px" class="vue-signature-container">
          <VueSignaturePad
            height="14rem"
            ref="signature_cli"
            :customStyle="{ background: 'white' }"
          />
          <strong :style="textStyle">
            <br />
            Firma de recibido y completo del cliente
          </strong>
        </div>
      </div>
      <p class="special-margin-top not-cut-printing">
        <strong>
          {{
            companyInformation.LEYENDA_INFERIOR
              ? companyInformation.LEYENDA_INFERIOR
              : ""
          }}</strong
        >
      </p>
      <div v-if="printing" style="margin-top: 1.5rem" class="not-cut-printing">
        Este pdf fue impreso por coconutcontrol.com
      </div>
    </div>
    <div class="container-print">
      <div class="add-global-controls">
        <b-button
          type="is-success"
          @click="print()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.sales.downloadDriverMaterialDelivery ===
              'S' &&
            permissions &&
            permissions.sales.downloadDriverMaterialDelivery === 'S'
          "
          >Descargar</b-button
        >

        <b-button type="is-success" @click="clearSign()"
          >Reiniciar firma del chofer</b-button
        >
        <b-button type="is-success" @click="clearSignClient()"
          >Reiniciar firma del cliente</b-button
        >

        <b-button
          type="is-success"
          @click="editDelivery()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.sales.saveDriverMaterialDeliverySign ===
              'S' &&
            permissions &&
            permissions.sales.saveDriverMaterialDeliverySign === 'S'
          "
          >Guardar cambios</b-button
        >

        <b-button type="is-danger" @click="closeModal()" class="cancel-button"
          >Regresar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import { openConfirmationModalSucces } from "../../../../utils/component-fns";
import { hexToRGB } from "../../../../utils/fns";
// @ is an alias to /src
export default {
  name: "PrintDriverMaterialDelivery",
  props: ["saleInformation", "deliveryInormation", "title"],
  components: {},
  data() {
    return {
      printing: false,
      printWithoutPrices: false,
      clientSendEmails: [],
      selectedEmail: "",
      sendClientEmail: true,
    };
  },
  mounted() {
    moment.locale("es");
    if (this.saleInformation.CLAVE_CLIENTE.EMAIL) {
      this.clientSendEmails.push(this.saleInformation.CLAVE_CLIENTE.EMAIL);
    }
    if (this.$store.getters.COMPANIES_EMAILS.length > 0) {
      this.selectedEmail = this.$store.getters.COMPANIES_EMAILS[0]._id;
    }

    if (this.deliveryInormation.FIRMA) {
      this.$refs.signature.fromDataURL(this.deliveryInormation.FIRMA);
    }

    if (this.deliveryInormation.FIRMA_CLI) {
      this.$refs.signature_cli.fromDataURL(this.deliveryInormation.FIRMA_CLI);
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc print the purchase order with the desire format
     */
    async print() {
      this.createPdfHtml();
      setTimeout(() => {
        this.printWithoutPrices = false;
      }, 500);
    },
    /**
     *
     * @desc cretes or generates a pdf file
     */
    async createPdfHtml() {
      this.printing = true;
      let pdfOptions = {
        filename: `${this.title} ${this.saleInformation.CLAVE_VENTA} ${this.saleInformation.CLAVE_CLIENTE.NOMBRE_EMPRESA} ${this.companyName}.pdf`,
        pagebreak: {
          avoid: [
            ".article-element",
            ".observations-element",
            ".vue-signature-container",
            ".not-cut-printing",
          ],
        },
        scale: 2,
        image: { type: "jpeg", quality: 0.98 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        margin: [0, 0.2, 0, 0.2],
      };
      let result = await html2pdf()
        .set(pdfOptions)
        .from(this.$refs.saleQuote)
        .save()
        .output("datauristring");
      this.printing = false;
      return result;
    },
    formatCreationDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    clearSign() {
      this.$refs.signature.undoSignature();
      this.deliveryInormation.FIRMA = "";
    },
    clearSignClient() {
      this.$refs.signature_cli.undoSignature();
      this.deliveryInormation.FIRMA_CLI = "";
    },
    async editDelivery() {
      try {
        const svg = this.$refs.signature.saveSignature("image/svg+xml");
        const svgClient =
          this.$refs.signature_cli.saveSignature("image/svg+xml");
        let signatureData = svg.data;
        let signatureDataClient = svgClient.data;
        if (this.deliveryInormation.FIRMA) {
          this.$refs.signature.addImages([this.deliveryInormation.FIRMA]);
          signatureData = await this.$refs.signature.mergeImageAndSignature(
            signatureData
          );
        }
        if (this.deliveryInormation.FIRMA_CLI) {
          this.$refs.signature_cli.addImages([
            this.deliveryInormation.FIRMA_CLI,
          ]);
          signatureDataClient =
            await this.$refs.signature_cli.mergeImageAndSignature(
              signatureDataClient
            );
        }
        let response = await this.$store.dispatch(
          "SAVEDRIVERMATERIALDELIVERY",
          {
            saleId: this.saleInformation._id,
            materialDeliveryId: this.deliveryInormation._id,
            signData: signatureData,
            signDataClient: signatureDataClient,
            observations: this.deliveryInormation.OBSERVA,
            observationsClient: this.deliveryInormation.OBSERVA_CLI,
          }
        );
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se editó exitosamente el envio de material",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al editar el envio de material en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al editar el envio de material en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    companyName() {
      return this.$store.getters.COMPANY_INFORMATION.name;
    },
    companyEmails() {
      return this.$store.getters.COMPANIES_EMAILS;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    deliveryDetails() {
      return this.deliveryInormation.ENVIO_DETALLES.map((singleDetail) => {
        const singleDetailWithProps = { ...singleDetail };
        if (singleDetail.CLAVE_ART) {
          for (const singleItem of this.saleInformation.VENTA_DET) {
            if (singleItem.CLAVE_ART._id === singleDetail.CLAVE_ART) {
              if (
                singleItem.PRESENTACION === "UB" &&
                singleItem.CLAVE_ART.UNIDAD_EMP
              ) {
                singleDetailWithProps.presentationName =
                  singleItem.CLAVE_ART.UNIDAD_EMP.DESCRIP_MED;
                singleDetailWithProps.PRESENTACION = singleItem.PRESENTACION;
              } else if (
                singleItem.PRESENTACION === "UB" &&
                !singleItem.CLAVE_ART.UNIDAD_EMP
              ) {
                singleDetailWithProps.presentationName =
                  "Unidad de medida base";
                singleDetailWithProps.PRESENTACION = singleItem.PRESENTACION;
              } else {
                // Set presentation name
                for (const singlePresentation of singleItem.CLAVE_ART
                  .PRESENTACIONES) {
                  if (singlePresentation._id === singleItem.PRESENTACION) {
                    singleDetailWithProps.presentationName =
                      singlePresentation.NOMBRE;
                    singleDetailWithProps.PRESENTACION =
                      singleItem.PRESENTACION;
                  }
                }
              }
            }
          }
        }
        return singleDetailWithProps;
      });
    },
    backgroundStyle() {
      return {
        "background-color": this.companyInformation.FONDO
          ? hexToRGB(this.companyInformation.FONDO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    backgroundSecondaryStyle() {
      return {
        "background-color": this.companyInformation.FONDO_SECUNDARIO
          ? hexToRGB(this.companyInformation.FONDO_SECUNDARIO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    textStyle() {
      return {
        color: this.companyInformation.TEXTO
          ? hexToRGB(this.companyInformation.TEXTO, 1)
          : "#363636",
      };
    },
    textSecondaryStyle() {
      return {
        color: this.companyInformation.TEXTO_SECUNDARIO
          ? hexToRGB(this.companyInformation.TEXTO_SECUNDARIO, 1)
          : "#363636",
      };
    },
  },
  watch: {
    purchaseOrderInformation() {
      if (this.purchaseOrderInformation.PRV.EMAIL) {
        this.clientSendEmails.push(this.purchaseOrderInformation.PRV.EMAIL);
      }
    },
    companyEmails() {
      this.selectedEmail = this.companyEmails[0]._id;
    },
  },
};
</script>

<style scoped>
@import "./style/PrintPurchaseOrder.css";
@import "../../style/Global.css";
</style>
