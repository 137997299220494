<template>
  <div class="container global-list-main-container">
    <h3 class="form-division">Predicción diaria usando machine learning</h3>

    <apexchart
      type="area"
      height="350"
      :options="predictionChart.chartOptions"
      :series="predictionChart.series"
      :key="predictionChartKey"
    ></apexchart>

    <h3 class="form-division special-margin">
      Predicción mensual usando análisis estadistico
    </h3>

    <b-field grouped class="special-margin">
      <b-field :label="currentMonthName" expanded label-position="inside">
        <b-input
          disabled
          placeholder="Ejemplo: 100"
          expanded
          :value="
            currentMonthPrediction
              ? currentMonthPrediction
              : 'Sin información necesaria realizar predicción'
          "
        ></b-input>
      </b-field>

      <b-field :label="nextMonthName" expanded label-position="inside">
        <b-input
          disabled
          placeholder="Ejemplo: 100"
          expanded
          :value="
            nextMonthPrediction
              ? nextMonthPrediction
              : 'Sin información necesaria realizar predicción'
          "
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division special-margin">
      Predicción mensual usando machine learning
    </h3>

    <b-field grouped class="special-margin">
      <b-field
        :label="currentMonthMachineLearningPrediction"
        expanded
        label-position="inside"
      >
        <b-input
          disabled
          placeholder="Ejemplo: 100"
          expanded
          :value="
            currentMonthMachineLearningPrediction
              ? currentMonthMachineLearningPrediction
              : 'Sin información necesaria realizar predicción'
          "
        ></b-input>
      </b-field>

      <b-field
        :label="nextMonthMachineLearningPrediction"
        expanded
        label-position="inside"
      >
        <b-input
          disabled
          placeholder="Ejemplo: 100"
          expanded
          :value="
            nextMonthMachineLearningPrediction
              ? nextMonthMachineLearningPrediction
              : 'Sin información necesaria realizar predicción'
          "
        ></b-input>
      </b-field>
    </b-field>
  </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from "@/event-bus";
import moment from "moment";
import { capitalizeFirstLetter } from "@/utils/fns";
export default {
  name: "SingleArticlePrediction",
  props: ["articleInformation"],
  components: {},
  data() {
    return {
      predictionChartKey: 0,
      predictionChart: {
        chartOptions: {
          chart: {
            type: "line",
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          markers: {
            size: 6,
            strokeOpacity: 0.9,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: "",
            align: "center",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              format: "dd/MM/yyyy",
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val ? parseFloat(val).toFixed(6) : 0;
              },
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yyyy",
            },
          },
          legend: {
            style: {
              fontSize: "1.1rem",
            },
            onItemClick: {
              toggleDataSeries: true,
            },
            onItemHover: {
              highlightDataSeries: true,
            },
          },
        },
        series: [
          {
            name: "ventas",
            data: [],
          },
        ],
      },
      articlePrediction: [],
      currentMonthMachineLearningPrediction: "",
      currentMonthPrediction: "",
      nextMonthPrediction: "",
      nextMonthMachineLearningPrediction: "",
      currentMonthName: "",
      nextMonthName: "",
    };
  },
  async created() {
    moment.locale("es");
  },
  async mounted() {
    const makePredictionResult = await this.$store.dispatch("MAKEPREDICTION", {
      CLAVE_ART: this.articleInformation.CLAVE_ART,
    });

    const makeStatisticsPredictionResult = await this.$store.dispatch(
      "MAKEPSTATISTICSREDICTION",
      {
        CLAVE_ART: this.articleInformation.CLAVE_ART,
      }
    );

    if (makePredictionResult.success) {
      let currentMonthSum = 0;
      let nextMonthSum = 0;

      const currentDate = moment();
      const currentMonth = currentDate.month() + 1;
      const currentYear = currentDate.year();

      const nextMonthDate = moment().add(1, "months");
      const nextMonth = nextMonthDate.month() + 1;
      const nextYear = nextMonthDate.year();

      const {
        data: { predictions },
      } = makePredictionResult;

      for (const prediction of predictions) {
        const dateComponents = prediction.date.split("-");

        if (
          currentMonth.toString() === dateComponents[1].replace("0", "") &&
          currentYear.toString() === dateComponents[0]
        ) {
          currentMonthSum += prediction.value;
        }

        if (
          nextMonth.toString() === dateComponents[1].replace("0", "") &&
          nextYear.toString() === dateComponents[0]
        ) {
          nextMonthSum += prediction.value;
        }

        this.predictionChart.series[0].data.push([
          new Date(prediction.date),
          prediction.value / nextMonthDate.daysInMonth(),
        ]);
      }

      this.currentMonthMachineLearningPrediction =
        currentMonthSum / currentDate.daysInMonth();

      this.nextMonthMachineLearningPrediction =
        currentMonthSum / nextMonthDate.daysInMonth();

      this.predictionChartKey += 1;

      this.currentMonthName = `${capitalizeFirstLetter(
        moment().format("MMMM")
      )} ${moment().format("YYYY")}`;

      this.nextMonthName = `${capitalizeFirstLetter(
        moment().add(1, "month").format("MMMM")
      )} ${moment().add(1, "month").format("YYYY")}`;
    }

    if (makeStatisticsPredictionResult.prediction) {
      this.currentMonthPrediction =
        makeStatisticsPredictionResult.prediction[0];
      this.currentMonthPrediction =
        makeStatisticsPredictionResult.prediction[1];
    }
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {},
  watch: {},
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
