/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-tooltip
          v-if="
            articles.length === 0 ||
            warehouses.length === 0 ||
            priceLists.length === 0 ||
            clients.length === 0
          "
          label="Primero debes agregar uno o varios artículos, listas de precios, clientes y almacenes al sistema o esperar a que el sistema cargue estos datos"
          multilined
        >
          <b-button type="is-primary" disabled>Agregar venta</b-button>
        </b-tooltip>

        <b-button
          type="is-primary"
          @click="openAddSale()"
          v-if="
            articles.length > 0 &&
            warehouses.length > 0 &&
            priceLists.length > 0 &&
            clients.length > 0
          "
          >Agregar venta</b-button
        >
      </div>

      <div class="control special-margin">
        <b-button
          type="is-primary"
          @click="startArticlesExport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.exportSalesArticles === 'S' &&
            permissions &&
            permissions.main.exportSalesArticles === 'S'
          "
          >Exportar artículos en ventas</b-button
        >
      </div>

      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field>
          <b-field label="Almacenes" expanded label-position="inside">
            <b-taginput
              :data="warehouses"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="NOMBRE_ALM"
              icon="warehouse"
              v-model="filterWarehouses"
              @add="(value) => changeFilterValue()"
              @remove="(value) => changeFilterValue()"
              ellipsis
              placeholder="Nombre de almacen"
              aria-close-label="Borrar almacen seleccionado"
              check-infinite-scroll
            >
            </b-taginput>
          </b-field>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field label="Cliente (Opcional)" expanded label-position="inside">
          <b-autocomplete
            placeholder="Cliente"
            :data="autoCompleteClient(clientName)"
            v-model="clientName"
            field="NOMBRE_EMPRESA"
            autocomplete
            icon="credit-card-outline"
            open-on-focus
            expanded
            clearable
            @select="
              (option) => {
                if (option) {
                  filterClient = option._id;
                  changeFilterValue();
                }
              }
            "
            @input="
              (input) => {
                if (input === '') {
                  filterClient = '';
                  changeFilterValue();
                }
              }
            "
            check-infinite-scroll
          >
            <template #empty>No se encontraron clientes</template>
          </b-autocomplete>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de ventas: <b>{{ sales.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="sales"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        scrollable
        ref="sales"
        :checked-rows.sync="checkedSales"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_VENTA}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CLAVE_VENTA"
      >
        <template #empty>
          <div class="has-text-centered">No hay ventas</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
            :custom-search="column.customSearch"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="!column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="showSale(props.row)"
                    v-if="
                      (props.row.STATUS_FAC === 'SF' ||
                        props.row.STATUS_FAC === 'PP') &&
                      props.row.STATUS !== 'CA'
                    "
                    >Ver o timbrar venta</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showSale(props.row)"
                    v-if="
                      props.row.STATUS_FAC === 'TI' ||
                      props.row.STATUS_FAC === 'PF' ||
                      props.row.STATUS_FAC === 'CA' ||
                      props.row.STATUS_FAC === 'FD' ||
                      props.row.STATUS === 'CA'
                    "
                    >Ver venta</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showInvoices(props.row)"
                    v-if="
                      props.row.STATUS_FAC === 'TI' ||
                      props.row.STATUS_FAC === 'PF' ||
                      props.row.STATUS_FAC === 'CA'
                    "
                    >Ver facturas</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showPurchaseOrders(props.row)"
                    >Ver ordenes de compra</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showMaterialDeliveries(props.row)"
                    >Ver envios de material</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openPrintSalesQuotesModal(props.row)"
                    >Enviar o imprimir venta</b-button
                  >

                  <b-button type="is-primary" @click="openPrintModal(props.row)"
                    >Imprimir o descargar ticket</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="cancelationSaleDeliveriesPopup(props.row)"
                    v-if="
                      props.row.STATUS !== 'CA' &&
                      props.row.STATUS_FAC !== 'TI' &&
                      props.row.STATUS_FAC !== 'PF' &&
                      checkCanDelete(props.row)
                    "
                    >Cancelar venta</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="cancelationInvoiceDeliveriesPopup(props.row)"
                    v-if="
                      props.row.STATUS !== 'CA' &&
                      (props.row.STATUS_FAC === 'TI' ||
                        props.row.STATUS_FAC === 'PF') &&
                      checkCanDelete(props.row) &&
                      checkCanDeleteInvoice(props.row)
                    "
                    >Cancelar venta</b-button
                  >

                  <b-button
                    v-if="
                      props.row.STATUS_FAC === 'SF' &&
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales.saveClarification ===
                        'S' &&
                      permissions &&
                      permissions.sales.saveClarification === 'S'
                    "
                    type="is-primary"
                    @click="openClarificationDeliveriesPopup(props.row)"
                    >Agregar aclaración</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showClarificationDetails(props.row)"
                    >Ver aclaraciónes</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="allowPartialSaleOrderValue(props.row, true)"
                    v-if="
                      !props.row.VENTA_PARCIAL_ORDEN &&
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales.editPartialSaleOrder ===
                        'S' &&
                      permissions &&
                      permissions.sales.editPartialSaleOrder === 'S'
                    "
                    >Permitir uso parcial en ordenes de compra</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="allowPartialSaleOrderValue(props.row, false)"
                    v-if="
                      props.row.VENTA_PARCIAL_ORDEN &&
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales.editPartialSaleOrder ===
                        'S' &&
                      permissions &&
                      permissions.sales.editPartialSaleOrder === 'S'
                    "
                    >Bloquear uso parcial en ordenes de compra</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="allowPartialSaleValue(props.row, true)"
                    v-if="
                      !props.row.VENTA_PARCIAL &&
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales.editPartialSale === 'S' &&
                      permissions &&
                      permissions.sales.editPartialSale === 'S'
                    "
                    >Permitir uso parcial en envios de material</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="allowPartialSaleValue(props.row, false)"
                    v-if="
                      props.row.VENTA_PARCIAL &&
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales.editPartialSale === 'S' &&
                      permissions &&
                      permissions.sales.editPartialSale === 'S'
                    "
                    >Bloquear uso parcial en envios de material</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="startRemoveSaleBackOrder(props.row, true)"
                    v-if="
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales.removeSaleBackOrder ===
                        'S' &&
                      permissions &&
                      permissions.sales.removeSaleBackOrder === 'S'
                    "
                    >Cancelar material faltante de enviar</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import PrintTicketModal from "./PrintTicketModal";
import PrintSalesQuotes from "../../Global/components/print-formats/PrintSalesQuotes";
import ClarificationsDetails from "./ClarificationsDetails";
import AddClarificationModal from "./AddClarificationModal.vue";
import InvoiceListModal from "./InvoiceListModal";
import AddSaleModal from "./AddSalesModal.vue";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import { openConfirmationModalDanger } from "@/utils/component-fns";
import CFDICancellationModal from "../../Accounting/components/CFDICancellationModal.vue";
import SalesPurchaseOrdersModal from "./SalesPurchaseOrdersModal";
import { toFixedNoRound } from "@/utils/fns";
import States from "@/data/states";
import SalesMaterialDeliveriesList from "@/modules/Sales/components/SalesMaterialDeliveriesList.vue";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "SalesList",
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 25,
      checkedSales: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_VENTA",
          label: "Folio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "statusFormatted",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "statusFacFormatted",
          label: "Estatus de timbrado",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "statusDeliveryFormatted",
          label: "Estatus de envio",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "USUARIO_CREACION",
          label: "Usuario creación",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "USUARIO_CONFIRMACION",
          label: "Usuario confirmación",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "posFormatted",
          label: "Punto de venta",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "TIENE_OC_FOR",
          label: "Tiene orden de compra",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "OC_CLIENTE",
          label: "Orden de compra del cliente",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CODIGO_ENVIO",
          label: "Código aleatorio único",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "advancedPaymentFormatted",
          label: "Anticipos",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "advancedPaymentCreditNote",
          label: "Amortizada",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "FACTURA_FOR",
          label: "Facturas",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "NOMBRE_CLIENTE",
          label: "Cliente",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALM",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_VENDEDOR",
          label: "Vendedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALMACEN_ORIGEN",
          label: "Origen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha de la venta",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "RESTA_FOR",
          label: "Restante a pagar en cuenta por cobrar",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
          customSort: this.sortyByCXCTotal,
        },
        {
          field: "CUENTA_STATUS_FOR",
          label: "Estatus de cuenta por cobrar",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "SUBTOTAL",
          label: "Subtotal",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "IVA",
          label: "Iva total",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortyByTotal,
        },
        {
          field: "RESTA_ENV_FOR",
          label: "Restante de envio",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "VENTA_PAR_FOR",
          label: "Venta de uso parcial en envio de material",
          sortable: true,
          searchable: true,
          display: false,
          money: false,
        },
        {
          field: "VENTA_PAR_OR_FOR",
          label: "Venta de uso parcial en ordenes de compra",
          sortable: true,
          searchable: true,
          display: false,
          money: false,
        },
        {
          field: "NO_PAGO",
          label: "Venta con pago sin facturar",
          sortable: true,
          searchable: true,
          display: true,
          money: false,
        },
      ],
      filterWarehouses: [],
      masks: Masks,
      states: States,
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      filterClient: "",
      clientName: "",
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    this.filterWarehouses = [];
    if (this.userInformation.ALMACENES.length > 0) {
      for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
        for (const singleWarehouse of this.warehouses) {
          if (singleSelectedWarehouse === singleWarehouse._id) {
            this.filterWarehouses.push(singleWarehouse);
          }
        }
      }
    }
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
      allPromises.push(
        this.$store.dispatch("GETSALES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouses: this.filterWarehouses.map((singleWarehouse) => {
            return singleWarehouse._id;
          }),
        })
      );
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPRICESLISTS"));
      allPromises.push(
        this.$store.dispatch("GETARTICLESPAGINATED", {
          skip: 0,
          limit: 50,
          saleActive: true,
        })
      );
      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
      allPromises.push(this.$store.dispatch("GETDISCOUNTCONCEPTS"));
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));
      allPromises.push(this.$store.dispatch("GETVEHICULES"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    openAddSale(sale) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddSaleModal,
        props: {
          type: 1,
          typeName: "venta",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddClarification(sale) {
      let newSale = this.formatSale(sale);
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddClarificationModal,
        props: {
          saleInformation: newSale,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showClarificationDetails(saleInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ClarificationsDetails,
        props: {
          saleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    formatSale(sale) {
      let currentItems = [];
      for (let singleArticle of sale.VENTA_DET) {
        currentItems.push({
          _id: singleArticle._id,
          article: singleArticle.CLAVE_ART,
          presentation: singleArticle.PRESENTACION,
          CANTIDAD_PRESENTACION: singleArticle.CANTIDAD_PRESENTACION,
          priceList: singleArticle.LISTA_PRECIOS,
          quantity: singleArticle.CANTIDAD_PE,
          quantitySended: singleArticle.CANTIDAD_EN
            ? singleArticle.CANTIDAD_EN
            : 0,
          quantityMissing:
            singleArticle.CANTIDAD_FE || singleArticle.CANTIDAD_FE === 0
              ? singleArticle.CANTIDAD_FE
              : singleArticle.CANTIDAD_PE,
          quantitySendedOriginal: singleArticle.CANTIDAD_EN
            ? singleArticle.CANTIDAD_EN
            : 0,
          quantityMissingOriginal:
            singleArticle.CANTIDAD_FE || singleArticle.CANTIDAD_FE === 0
              ? singleArticle.CANTIDAD_FE
              : singleArticle.CANTIDAD_PE,
          RENGLON: singleArticle.RENGLON,
          price: singleArticle.COSTO_UNI,
          iva: singleArticle.PORC_IVA,
          ieps: singleArticle.PORC_IEPS,
          IVA_GTO: singleArticle.IVA_GTO,
          IEPS_GTO: singleArticle.IEPS_GTO,
          total: singleArticle.TOTAL,
          costTotal: singleArticle.COST_GTO,
          discountTotal: singleArticle.DESC_GTO,
          costs: singleArticle.COSTOS ? singleArticle.COSTOS : [],
          discounts: singleArticle.DESCS ? singleArticle.DESCS : [],
          selectedOption: "empty",
          EXENTO: singleArticle.EXENTO ? singleArticle.EXENTO : "N",
          COSTO_MIN_VTA: singleArticle.COSTO_MIN_VTA,
          UTILIDAD_UNI: singleArticle.utilityTotal,
          UTILIDAD: singleArticle.utilityTotal,
        });
      }
      let saleInformation = {
        _id: sale._id,
        CLAVE_VENTA: sale.CLAVE_VENTA,
        CLIENTE: sale.CLAVE_CLIENTE._id,
        CLIENTE_FORMATTED_INFORMATION: sale.CLAVE_CLIENTE,
        ALMACEN: sale.CLAVE_ALMACEN._id,
        COSTOS: sale.COSTOS ? sale.COSTOS : [],
        DESCS: sale.DESCS ? sale.DESCS : [],
        OBSERVA: sale.OBSERVA,
        OBSERVA_IMP: sale.OBSERVA_IMP,
        currentItems: currentItems,
        FECHA: sale.FECHA,
        subtotal: sale.SUBTOTAL,
        total: sale.TOTAL,
        discounts: sale.DESC_GTO,
        costs: sale.COST_GTO,
        iva: sale.IVA,
        ieps: sale.IEPS,
        exempt: sale.EXENTO,
        shippingQuantity: sale.FLETE,
        shippingPercentage: sale.PORC_FLETE,
        printTimes: sale.IMPRESO,
        STATUS: sale.STATUS,
        EXENTO: sale.EXENTO,
        CLAVE_USO_SAT: sale.CLAVE_USO_SAT,
        CLAVE_REGIMEN: sale.CLAVE_REGIMEN,
        METODO_P: sale.METODO_P,
        CONDICION_P: sale.CONDICION_P,
        PERI_PAGO: sale.PERI_PAGO,
        FORMA_P: sale.FORMA_P,
        REFERENCIA: sale.REFERENCIA,
        NO_CUENTA: sale.NO_CUENTA,
        NO_CLABE: sale.NO_CLABE,
        NO_TARJETA: sale.NO_TARJETA,
        NO_CHEQUE: sale.NO_CHEQUE,
        STATUS_FAC: sale.STATUS_FAC,
        requireInvoice: sale.requireInvoice,
        requireInvoiceAfterSend: sale.requireInvoiceAfterSend,
        requireParcialInvoiceAfterSend: sale.requireParcialInvoiceAfterSend,
        relateCFDI: sale.relateCFDI,
        divideCFDI: sale.divideCFDI,
        UUID_CFDI: sale.UUID_CFDI,
        TIPO_R: sale.TIPO_R,
        VENDEDOR: sale.VENDEDOR,
        NOMBRE_VENDEDOR: sale.NOMBRE_VENDEDOR,
        UTILIDAD: sale.UTILIDAD,
        DESBLOQUEO_CLIENTE: sale.DESBLOQUEO_CLIENTE,
        DESBLOQUEO_ALMACEN: sale.DESBLOQUEO_ALMACEN,
        sendMaterial: sale.sendMaterial,
        STATUS_ENV: sale.STATUS_ENV,
        useAdvancedPayment: sale.useAdvancedPayment,
        CLAVE_ANTICIPO: sale.CLAVE_ANTICIPO,
        CANTIDAD_ANTICIPO: sale.CANTIDAD_ANTICIPO,
        FECHA_ENVIO: sale.FECHA_ENVIO,
        clientPickup: sale.clientPickup,
        OC_CLIENTE: sale.OC_CLIENTE,
        USUARIO_CREACION: sale.USUARIO_CREACION,
        USUARIO_CONFIRMACION: sale.USUARIO_CONFIRMACION,
        CLAVE_VEHICULO: sale.CLAVE_VEHICULO,
        CODIGO_ENVIO: sale.CODIGO_ENVIO,
        ALMACEN_ORIGEN_INFO: sale.ALMACEN_ORIGEN_INFO,
        POS: sale.POS,
        RAZON_SOCIAL: sale.RAZON_SOCIAL,
      };
      return saleInformation;
    },
    showSale(sale) {
      let newSale = this.formatSale(sale);
      let currentIndex = newSale.currentItems.length;
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddSaleModal,
        props: {
          currentIndexProp: currentIndex,
          type: 1,
          typeName: "venta",
          saleInformation: newSale,
          blockEdit: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async cancelSale(sale) {
      try {
        let response = await this.$store.dispatch("CANCELSALE", {
          saleId: sale._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente la venta",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al cancelar la venta en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al cancelar la venta en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    cancelInvoice(sale) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: CFDICancellationModal,
        props: {
          saleId: sale._id,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showAddInvoice(sale) {
      let newSale = this.formatSale(sale);
      let currentIndex = newSale.currentItems.length;
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddSaleModal,
        props: {
          currentIndexProp: currentIndex,
          type: 1,
          typeName: "venta",
          saleInformation: newSale,
          blockEdit: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showInvoices(saleInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: InvoiceListModal,
        props: {
          saleInformation: saleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showPurchaseOrders(saleInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SalesPurchaseOrdersModal,
        props: {
          saleInformation: saleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showMaterialDeliveries(sale) {
      let currentItems = [];
      for (let singleArticle of sale.VENTA_DET) {
        currentItems.push({
          _id: singleArticle._id,
          article: singleArticle.CLAVE_ART,
          presentation: singleArticle.PRESENTACION,
          CANTIDAD_PRESENTACION: singleArticle.CANTIDAD_PRESENTACION,
          priceList: singleArticle.LISTA_PRECIOS,
          quantity: singleArticle.CANTIDAD_PE,
          quantitySended: singleArticle.CANTIDAD_EN
            ? singleArticle.CANTIDAD_EN
            : 0,
          quantityMissing:
            singleArticle.CANTIDAD_FE || singleArticle.CANTIDAD_FE === 0
              ? singleArticle.CANTIDAD_FE
              : singleArticle.CANTIDAD_PE,
          quantitySendedOriginal: singleArticle.CANTIDAD_EN
            ? singleArticle.CANTIDAD_EN
            : 0,
          quantityMissingOriginal:
            singleArticle.CANTIDAD_FE || singleArticle.CANTIDAD_FE === 0
              ? singleArticle.CANTIDAD_FE
              : singleArticle.CANTIDAD_PE,
          RENGLON: singleArticle.RENGLON,
          price: singleArticle.COSTO_UNI,
          iva: singleArticle.PORC_IVA,
          ieps: singleArticle.PORC_IEPS,
          IVA_GTO: singleArticle.IVA_GTO,
          IEPS_GTO: singleArticle.IEPS_GTO,
          total: singleArticle.TOTAL,
          costTotal: singleArticle.COST_GTO,
          discountTotal: singleArticle.DESC_GTO,
          costs: singleArticle.COSTOS ? singleArticle.COSTOS : [],
          discounts: singleArticle.DESCS ? singleArticle.DESCS : [],
          selectedOption: "empty",
          EXENTO: singleArticle.EXENTO ? singleArticle.EXENTO : "N",
          COSTO_MIN_VTA: singleArticle.COSTO_MIN_VTA,
          UTILIDAD_UNI: singleArticle.utilityTotal,
          UTILIDAD: singleArticle.utilityTotal,
        });
      }
      let formattedSaleInformation = {
        _id: sale._id,
        CLAVE_VENTA: sale.CLAVE_VENTA,
        CLIENTE: sale.CLAVE_CLIENTE._id,
        ALMACEN: sale.CLAVE_ALMACEN._id,
        COSTOS: sale.COSTOS ? sale.COSTOS : [],
        DESCS: sale.DESCS ? sale.DESCS : [],
        OBSERVA: sale.OBSERVA,
        OBSERVA_IMP: sale.OBSERVA_IMP,
        currentItems: currentItems,
        FECHA: sale.FECHA,
        subtotal: sale.SUBTOTAL,
        total: sale.TOTAL,
        discounts: sale.DESC_GTO,
        costs: sale.COST_GTO,
        iva: sale.IVA,
        ieps: sale.IEPS,
        exempt: sale.EXENTO,
        shippingQuantity: sale.FLETE,
        shippingPercentage: sale.PORC_FLETE,
        printTimes: sale.IMPRESO,
        STATUS: sale.STATUS,
        EXENTO: sale.EXENTO,
        CLAVE_USO_SAT: sale.CLAVE_USO_SAT,
        CLAVE_REGIMEN: sale.CLAVE_REGIMEN,
        METODO_P: sale.METODO_P,
        CONDICION_P: sale.CONDICION_P,
        PERI_PAGO: sale.PERI_PAGO,
        FORMA_P: sale.FORMA_P,
        REFERENCIA: sale.REFERENCIA,
        NO_CUENTA: sale.NO_CUENTA,
        NO_CLABE: sale.NO_CLABE,
        NO_TARJETA: sale.NO_TARJETA,
        NO_CHEQUE: sale.NO_CHEQUE,
        STATUS_FAC: sale.STATUS_FAC,
        requireInvoice: sale.requireInvoice,
        requireInvoiceAfterSend: sale.requireInvoiceAfterSend,
        requireParcialInvoiceAfterSend: sale.requireParcialInvoiceAfterSend,
        relateCFDI: sale.relateCFDI,
        divideCFDI: sale.divideCFDI,
        UUID_CFDI: sale.UUID_CFDI,
        TIPO_R: sale.TIPO_R,
        VENDEDOR: sale.VENDEDOR,
        NOMBRE_VENDEDOR: sale.NOMBRE_VENDEDOR,
        UTILIDAD: sale.UTILIDAD,
        DESBLOQUEO_CLIENTE: sale.DESBLOQUEO_CLIENTE,
        DESBLOQUEO_ALMACEN: sale.DESBLOQUEO_ALMACEN,
        sendMaterial: sale.sendMaterial,
        STATUS_ENV: sale.STATUS_ENV,
        useAdvancedPayment: sale.useAdvancedPayment,
        CLAVE_ANTICIPO: sale.CLAVE_ANTICIPO,
        CANTIDAD_ANTICIPO: sale.CANTIDAD_ANTICIPO,
        FECHA_ENVIO: sale.FECHA_ENVIO,
        clientPickup: sale.clientPickup,
        OC_CLIENTE: sale.OC_CLIENTE,
        USUARIO_CREACION: sale.USUARIO_CREACION,
        USUARIO_CONFIRMACION: sale.USUARIO_CONFIRMACION,
        CLAVE_VEHICULO: sale.CLAVE_VEHICULO,
        CODIGO_ENVIO: sale.CODIGO_ENVIO,
        POS: sale.POS,
        RAZON_SOCIAL: sale.RAZON_SOCIAL,
      };

      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SalesMaterialDeliveriesList,
        props: {
          saleInformation: sale,
          formattedSaleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    openPrintSalesQuotesModal(sale) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintSalesQuotes,
        props: {
          saleInformation: sale,
          title: "Venta",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openPrintModal(sale) {
      const formattedSale = this.formatSale(sale);
      formattedSale.CLAVE_ALMACEN = sale.CLAVE_ALMACEN;
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintTicketModal,
        props: {
          saleInformation: formattedSale,
          type: 1,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async changeFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETSALES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: this.filterWarehouses.map((singleWarehouse) => {
              return singleWarehouse._id;
            }),
            client: this.filterClient,
          })
        );

        await Promise.all(allPromises);
      }
    },
    checkCanDelete(saleInformation) {
      const isToday = moment(saleInformation.FECHA).isSame(moment(), "day");
      return (
        (this.companyPaymentPlanModules.sales.cancelSale === "S" &&
          this.permissions.sales.cancelSale === "S") ||
        (this.companyPaymentPlanModules.sales.dailyCancelSale === "S" &&
          this.permissions.sales.dailyCancelSale === "S" &&
          isToday)
      );
    },
    checkCanDeleteInvoice(saleInformation) {
      return (
        moment(saleInformation.FECHA).isSame(new Date(), "year") &&
        moment(saleInformation.FECHA).isSame(new Date(), "month")
      );
    },
    startArticlesExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de artículos",
        message: `¿Desea confirmar la exportación de los artículos a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportArticleData(),
      });
    },
    exportArticleData() {
      let articlesData = [];
      for (const singleSale of this.sales) {
        for (const singleDetail of singleSale.VENTA_DET) {
          const singleArticleFormatted = {};

          let utilityPercentage = toFixedNoRound(
            ((singleDetail.COSTO_UNI -
              singleDetail.DESC_GTO +
              singleDetail.COST_GTO) /
              singleDetail.COSTO_MIN_VTA -
              1) *
              100,
            3
          );

          let currentSaleStatus = "Activa";

          if (singleSale.STATUS === "AC") {
            currentSaleStatus = "Activa";
          } else if (singleSale.STATUS === "CA") {
            currentSaleStatus = "Cancelada";
          } else if (singleSale.STATUS === "AN") {
            currentSaleStatus = "Activa Nota Crédito";
          }

          let currentSaleInvoiceFormatted = "";
          if (singleSale.FACTURAS) {
            currentSaleInvoiceFormatted = singleSale.FACTURAS.map(
              (singleInvoice) => {
                return singleInvoice.CLAVE_FACTURA;
              }
            ).toString();
          }

          singleArticleFormatted["RFC"] = singleSale.CLAVE_CLIENTE.RFC_CLI;
          singleArticleFormatted["CLIENTE"] = singleSale.NOMBRE_CLIENTE;
          singleArticleFormatted["ALMACEN"] =
            singleSale.CLAVE_ALMACEN.CLAVE_ALMACEN;
          singleArticleFormatted["ESTADO"] = "";
          singleArticleFormatted["FOLIO DE VENTA"] = singleSale.CLAVE_VENTA;
          singleArticleFormatted["FOLIO DE FACTURA"] =
            currentSaleInvoiceFormatted;
          singleArticleFormatted["ESTATUS DE VENTA"] = currentSaleStatus;
          singleArticleFormatted["FECHA DE VENTA"] =
            singleSale.formatedCreationDate;
          singleArticleFormatted["CLAVE DEL ARTICULO"] =
            singleDetail.CLAVE_ART.CLAVE_ART;
          singleArticleFormatted["DESCRIPCIÓN DEL ARTICULO"] =
            singleDetail.CLAVE_ART.DESCRIPCION;
          singleArticleFormatted["CANTIDAD"] = singleDetail.CANTIDAD_PE;
          singleArticleFormatted["PRECIO DE VENTA"] = singleDetail.COSTO_UNI;
          singleArticleFormatted["COSTO"] = singleDetail.COSTO_MIN_VTA
            ? singleDetail.COSTO_MIN_VTA
            : 0;
          singleArticleFormatted["PORCENTAJE DE IVA"] = singleDetail.PORC_IVA
            ? singleDetail.PORC_IVA
            : 0;
          singleArticleFormatted["PORCENTAJE DE IEPS"] = singleDetail.PORC_IEPS
            ? singleDetail.PORC_IEPS
            : 0;
          singleArticleFormatted["TOTAL DE IVA"] = singleDetail.IVA_GTO
            ? singleDetail.IVA_GTO
            : 0;
          singleArticleFormatted["TOTAL DE IEPS"] = singleDetail.IEPS_GTO
            ? singleDetail.IEPS_GTO
            : 0;
          singleArticleFormatted["DESCUENTO"] = singleDetail.DESC_GTO;
          singleArticleFormatted["DESCUENTOS TOTAL"] = toFixedNoRound(
            singleDetail.DESC_GTO * singleDetail.CANTIDAD_PE,
            3
          );
          singleArticleFormatted["INDIRECTOS"] = singleDetail.COST_GTO;
          singleArticleFormatted["INDIRECTOS TOTAL"] = toFixedNoRound(
            singleDetail.COST_GTO * singleDetail.CANTIDAD_PE,
            3
          );
          singleArticleFormatted["UTILIDAD"] = singleDetail.UTILIDAD_UNI
            ? singleDetail.UTILIDAD_UNI === singleDetail.UTILIDAD
              ? toFixedNoRound(
                  singleDetail.UTILIDAD_UNI / singleDetail.CANTIDAD_P,
                  3
                )
              : singleDetail.UTILIDAD_UNI
            : "NA";
          singleArticleFormatted["PORCENTAJE DE UTILIDAD"] = utilityPercentage
            ? utilityPercentage
            : "NA";
          singleArticleFormatted["UTILIDAD TOTAL"] = singleDetail.UTILIDAD
            ? singleDetail.UTILIDAD
            : "NA";
          singleArticleFormatted["PESO (KGS)"] =
            singleDetail.CLAVE_ART.CANT_PESO;
          singleArticleFormatted["VOLUMEN (MT3)"] =
            singleDetail.CLAVE_ART.CANT_VOL;

          for (const singleState of this.states) {
            if (
              singleSale.CLAVE_CLIENTE.ESTADO_CLI &&
              singleSale.CLAVE_CLIENTE.ESTADO_CLI === singleState.CLAVE
            ) {
              singleArticleFormatted["ESTADO"] = singleState.NOMBRE;
            }
          }

          singleArticleFormatted["VENDEDOR"] = singleSale.NOMBRE_VENDEDOR
            ? singleSale.NOMBRE_VENDEDOR
            : "";

          articlesData.push(singleArticleFormatted);
        }
      }
      let payload = {
        data: articlesData,
        name: "Artículos-venta--" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    sortyByTotal(a, b, isAsc) {
      if (isAsc) {
        return a.TOTAL.localeCompare(b.TOTAL, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.TOTAL.localeCompare(a.TOTAL, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
    sortyByCXCTotal(a, b, isAsc) {
      if (isAsc) {
        return String(a.RESTA_FOR).localeCompare(
          String(b.RESTA_FOR),
          undefined,
          {
            numeric: true,
            sensitivity: "base",
          }
        );
      } else {
        return String(b.RESTA_FOR).localeCompare(
          String(a.RESTA_FOR),
          undefined,
          {
            numeric: true,
            sensitivity: "base",
          }
        );
      }
    },
    async allowPartialSaleValue(sale, partialValue) {
      try {
        let response = await this.$store.dispatch("EDITPARTIALSALE", {
          saleId: sale._id,
          newPartialValue: {
            VENTA_PARCIAL: partialValue,
          },
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se actualizó exitosamente la venta",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al actualizar la venta en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al actualizar la venta en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async allowPartialSaleOrderValue(sale, partialValue) {
      try {
        let response = await this.$store.dispatch("EDITPARTIALSALEORDER", {
          saleId: sale._id,
          newPartialValue: {
            VENTA_PARCIAL_ORDEN: partialValue,
          },
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se actualizó exitosamente la venta",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al actualizar la venta en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al actualizar la venta en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    cancelationSaleDeliveriesPopup(sale) {
      if (sale.RESTA < sale.TOTAL) {
        this.$buefy.dialog.confirm({
          title: "Confirmar cancelación de venta",
          message: `Esta venta cuenta con uno o más envios de material, ¿Estas seguro que deseas cancelar la venta?`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: true,
          onConfirm: () => {
            this.startCancelEvent(
              "danger",
              () => {
                this.cancelSale(sale);
              },
              "cancelar la venta"
            );
          },
        });
        return;
      }

      this.startCancelEvent(
        "danger",
        () => {
          this.cancelSale(sale);
        },
        "cancelar la venta"
      );
    },
    cancelationInvoiceDeliveriesPopup(sale) {
      if (sale.RESTA < sale.TOTAL) {
        this.$buefy.dialog.confirm({
          title: "Confirmar cancelación de venta",
          message: `Esta venta cuenta con uno o más envios de material, ¿Estas seguro que deseas cancelar la venta?`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: true,
          onConfirm: () => {
            this.cancelInvoice(sale);
          },
        });
        return;
      }

      this.cancelInvoice(sale);
    },
    openClarificationDeliveriesPopup(sale) {
      if (sale.RESTA < sale.TOTAL) {
        this.$buefy.dialog.confirm({
          title: "Confirmar creación de aclaración",
          message: `Esta venta cuenta con uno o más envios de material, ¿Estas seguro que deseas crear una aclaración?`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: true,
          onConfirm: () => {
            this.openAddClarification(sale);
          },
        });
        return;
      }

      this.openAddClarification(sale);
    },
    async removeSaleBackOrder(saleInformation) {
      try {
        let response = await this.$store.dispatch("REMOVESALEBACKORDER", {
          saleId: saleInformation._id,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Se canceló el material faltante de enviar exitosamente la venta",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cancelar el material faltante de enviar en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al cancelar el material faltante de enviar en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    startRemoveSaleBackOrder(saleInformation) {
      this.startCancelEvent(
        "danger",
        () => {
          this.removeSaleBackOrder(saleInformation);
        },
        "Cancelar material faltante de enviar"
      );
    },
    isSaleNoInvoiceWithPayment(saleInformation) {
      if (saleInformation.STATUS_FAC !== "SF" || !saleInformation.CUENTAS_COB) {
        return false;
      }

      if (saleInformation.POS === "S" && saleInformation.METODO_P !== "D") {
        return false;
      }

      for (const singleAccount of saleInformation.CUENTAS_COB) {
        if (singleAccount.RESTA > 0) {
          return false;
        }
      }

      return true;
    },
    autoCompleteClient(value) {
      if (!this.clients) {
        return [];
      }
      return this.clients
        .filter((singleClient) => {
          return (
            singleClient.CLAVE_CLI.toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0 ||
            singleClient.NOMBRE_EMPRESA.toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0
          );
        })
        .sort((a, b) => {
          return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
        });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    warehouses() {
      this.filterWarehouses = [];
      if (this.userInformation.ALMACENES.length > 0) {
        for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
          for (const singleWarehouse of this.warehouses) {
            if (singleSelectedWarehouse === singleWarehouse._id) {
              this.filterWarehouses.push(singleWarehouse);
            }
          }
        }
      }
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    sales() {
      return this.$store.getters.SALES.map((singleSale) => {
        const saleWithProps = { ...singleSale };

        saleWithProps.TOTAL = String(singleSale.TOTAL);

        saleWithProps.posFormatted = singleSale.POS === "S" ? "Sí" : "No";

        if (singleSale.CLAVE_ALMACEN) {
          saleWithProps.NOMBRE_ALM = singleSale.CLAVE_ALMACEN.NOMBRE_ALM;
        }

        if (singleSale.CLAVE_CLIENTE) {
          saleWithProps.NOMBRE_CLIENTE =
            singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        }

        saleWithProps.formatedCreationDate = moment(singleSale.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );

        saleWithProps.advancedPaymentFormatted = "";
        if (saleWithProps.ANTICIPO_INFO) {
          saleWithProps.advancedPaymentFormatted =
            saleWithProps.ANTICIPO_INFO.NUM_FOLIO;
        }

        saleWithProps.advancedPaymentCreditNote = "No";
        if (saleWithProps.FACTURAS) {
          for (const singleInvoice of saleWithProps.FACTURAS) {
            if (singleInvoice.NOTAC) {
              for (const singleCreditNote of singleInvoice.NOTAC) {
                if (singleCreditNote.TIPO === "A") {
                  saleWithProps.advancedPaymentCreditNote = "Si";
                }
              }
            }
          }
        }

        saleWithProps.VENTA_PAR_FOR = "No";
        if (saleWithProps.VENTA_PARCIAL) {
          saleWithProps.VENTA_PAR_FOR = "Si";
        }

        saleWithProps.VENTA_PAR_OR_FOR = "No";
        if (saleWithProps.VENTA_PARCIAL_ORDEN) {
          saleWithProps.VENTA_PAR_OR_FOR = "Si";
        }

        saleWithProps.TIENE_OC_FOR = "No";
        if (
          saleWithProps.ORDENES_COMPRA &&
          saleWithProps.ORDENES_COMPRA.length > 0
        ) {
          saleWithProps.TIENE_OC_FOR = "Si";
        }

        saleWithProps.FACTURA_FOR = "";
        if (singleSale.FACTURAS) {
          saleWithProps.FACTURA_FOR = singleSale.FACTURAS.map(
            (singleInvoice) => {
              return singleInvoice.CLAVE_FACTURA;
            }
          ).toString();
        }

        if (saleWithProps.STATUS === "AC") {
          saleWithProps.statusFormatted = "Activa";
        } else if (saleWithProps.STATUS === "CA") {
          saleWithProps.statusFormatted = "Cancelada";
          saleWithProps.RESTA = 0;
        } else if (saleWithProps.STATUS === "AN") {
          saleWithProps.statusFormatted = "Activa Nota Crédito";
        }

        if (!saleWithProps.STATUS_FAC || saleWithProps.STATUS_FAC === "SF") {
          saleWithProps.statusFacFormatted = "Sin timbrar";
        } else if (saleWithProps.STATUS_FAC === "TI") {
          saleWithProps.statusFacFormatted = "Timbrada";
        } else if (saleWithProps.STATUS_FAC === "CA") {
          saleWithProps.statusFacFormatted = "Cancelada";
        } else if (saleWithProps.STATUS_FAC === "FD") {
          saleWithProps.statusFacFormatted = "En factura global";
        } else if (saleWithProps.STATUS_FAC === "PF") {
          saleWithProps.statusFacFormatted = "Parcialmente timbrada";
        } else if (saleWithProps.STATUS_FAC === "PP") {
          saleWithProps.statusFacFormatted = "Pendiente de timbrar";
        }

        saleWithProps.RESTA_FOR = 0;
        if (saleWithProps.CUENTAS_COB) {
          for (const singleIncome of saleWithProps.CUENTAS_COB) {
            saleWithProps.RESTA_FOR += singleIncome.RESTA;
          }
          saleWithProps.RESTA_FOR =
            saleWithProps.RESTA_FOR > 0 ? String(saleWithProps.RESTA_FOR) : 0;
        }

        saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
        if (saleWithProps.CUENTAS_COB) {
          for (const singleIncome of saleWithProps.CUENTAS_COB) {
            if (singleIncome.STATUS === "AC")
              saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
            if (singleIncome.STATUS === "PE")
              saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
            if (singleIncome.STATUS === "PA")
              saleWithProps.CUENTA_STATUS_FOR = "Totalmente pagada";
            if (singleIncome.STATUS === "CA") {
              saleWithProps.CUENTA_STATUS_FOR = "Cancelada";
              saleWithProps.RESTA_FOR = 0;
            }
            if (singleIncome.STATUS === "VE") {
              saleWithProps.CUENTA_STATUS_FOR = "Vencida";
            }
          }
        }

        saleWithProps.NO_PAGO = "No";
        if (this.isSaleNoInvoiceWithPayment(singleSale)) {
          saleWithProps.NO_PAGO = "Si";
        }

        saleWithProps.RESTA_ENV_FOR =
          saleWithProps.RESTA > 0 ? saleWithProps.RESTA : 0;

        if (saleWithProps.STATUS === "CA") {
          saleWithProps.RESTA_ENV_FOR = 0;
        }

        if (!saleWithProps.STATUS_ENV || saleWithProps.STATUS_ENV === "P") {
          saleWithProps.statusDeliveryFormatted = "Pendiente";
        } else if (saleWithProps.STATUS_ENV === "I") {
          saleWithProps.statusDeliveryFormatted = "Incompleto";
        } else if (saleWithProps.STATUS_ENV === "C") {
          saleWithProps.statusDeliveryFormatted = "Completo";
          saleWithProps.RESTA = 0;
        }

        return saleWithProps;
      });
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
    clients() {
      return this.$store.getters.CLIENTS;
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
