<template>
  <div class="container add-global-modal-main-container ticket-main-container">
    <h1 class="add-global-modal-main-title hidden-print">
      Imprimir o descargar ticket
    </h1>
    <div class="ticket-container special-margin" ref="ticket">
      <div class="ticket" v-if="!printWithoutPrices">
        <p class="centered company-name">{{ companyInformation.name }}<br /></p>
        <p class="centered black-box">
          {{
            !isPreSale
              ? "Venta"
              : !isQuote
              ? "Venta pendiente de pago"
              : "Cotización"
          }}
        </p>
        <p
          class="centered company-info"
          v-if="
            saleInformation &&
            saleInformation.CLAVE_ALMACEN &&
            saleInformation.CLAVE_ALMACEN.NOMBRE_ALM
          "
        >
          {{ saleInformation.CLAVE_ALMACEN.NOMBRE_ALM }}
          {{ saleInformation.CLAVE_ALMACEN.CEL_ALM }}
          {{ saleInformation.CLAVE_ALMACEN.EMAIL }}
        </p>

        <p
          class="centered company-info"
          v-if="
            !saleInformation ||
            !saleInformation.CLAVE_ALMACEN ||
            !saleInformation.CLAVE_ALMACEN.NOMBRE_ALM ||
            !saleInformation.CLAVE_ALMACEN.DOMICIL
          "
        >
          Dirección: {{ companyInformation.DOMICIL }}
          {{ companyInformation.NUM_INTER }},
          {{ companyInformation.NOMBRE_COLONIA }},
          {{ companyInformation.CP }}
          {{ companyInformation.NOMBRE_MUNICIPIO }},
          {{ companyInformation.ESTADO }}
        </p>

        <p
          class="centered company-info"
          v-if="
            saleInformation &&
            saleInformation.CLAVE_ALMACEN &&
            saleInformation.CLAVE_ALMACEN.NOMBRE_ALM &&
            saleInformation.CLAVE_ALMACEN.DOMICIL
          "
        >
          Dirección: {{ saleInformation.CLAVE_ALMACEN.DOMICIL }}
          {{ saleInformation.CLAVE_ALMACEN.NUM_INTER }},
          {{ saleInformation.CLAVE_ALMACEN.NOMBRE_COLONIA }},
          {{ saleInformation.CLAVE_ALMACEN.CP }}
          {{ saleInformation.CLAVE_ALMACEN.NOMBRE_MUNICIPIO }},
          {{ saleInformation.CLAVE_ALMACEN.ESTADO }}
        </p>
        <div class="ticket-information">
          <p class="ticket-number" v-if="saleInformation.CLAVE_VENTA">
            Cliente:
            {{
              saleInformation.CLIENTE_FORMATTED_INFORMATION
                ? saleInformation.CLIENTE_FORMATTED_INFORMATION.NOMBRE_EMPRESA
                : "Sin información"
            }}
          </p>

          <p class="ticket-number" v-if="saleInformation.CLAVE_VENTA">
            Número de Cliente:
            {{
              saleInformation.CLIENTE_FORMATTED_INFORMATION
                ? saleInformation.CLIENTE_FORMATTED_INFORMATION.CLAVE_CLI
                : "Sin información"
            }}
          </p>

          <p class="ticket-number" v-if="saleInformation.CLAVE_VENTA">
            Ticket: #{{ saleInformation.CLAVE_VENTA }}
          </p>
          <p class="ticket-date">
            Fecha:
            {{
              moment(saleInformation.FECHA)
                .tz("America/Mexico_City")
                .format("DD/MM/yyyy hh:mm")
            }}
          </p>
        </div>
        <table>
          <thead>
            <tr>
              <th class="description">Descripción</th>
              <th class="price">Costo</th>
            </tr>
          </thead>
          <!-- eslint-disable vue/no-use-v-if-with-v-for -->
          <tbody>
            <tr
              v-for="singleItem in saleItems"
              :key="singleItem.RENGLON"
              class="ticket-item"
              v-if="singleItem.article"
            >
              <td class="description">
                {{ singleItem.quantity }}
                /
                {{
                  singleItem.article.UNIDAD_EMP
                    ? singleItem.article.UNIDAD_EMP.DESCRIP_MED
                    : "PIEZA"
                }}
                /
                {{ singleItem.article.CLAVE_ART }}
                <br />
                {{
                  singleItem.presentation === "UB"
                    ? singleItem.article.DESCRIPCION
                    : singleItem.presentationName
                }}
                /
                {{ singleItem.article.UBICACION }}
              </td>
              <td class="quantity">
                {{ singleItem.finalPrice | money("MXN", 2) }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="totals-container">
          <div class="subtotal">
            Subtotal: {{ "      " }}
            {{ saleInformation.subtotal | money("MXN", 2) }}
          </div>
          <div class="iva">
            Iva: {{ "      " }}
            {{ saleInformation.iva | money("MXN", 2) }}
          </div>
          <div class="ieps">
            Ieps: {{ "      " }}
            {{ saleInformation.ieps | money("MXN", 2) }}
          </div>
          <div class="totals">
            Total: {{ "      " }} {{ saleInformation.total | money("MXN", 2) }}
          </div>
        </div>
        <div class="barcode-all-container">
          <barcode
            :value="saleInformation.CLAVE_VENTA"
            height="32px"
            width="1rem"
            text-align="center"
            font-size="14px"
            class="barcode-container"
          >
            Código de barras no disponible
          </barcode>
        </div>
        <p class="centered observations">{{ saleInformation.OBSERVA_IMP }}</p>
        <p class="centered thank-you">
          Atendido por:
          {{
            saleInformation.NOMBRE_VENDEDOR
              ? saleInformation.NOMBRE_VENDEDOR
              : ""
          }}
        </p>
        <p class="centered thank-you">¡Gracias por su preferencia!</p>
      </div>

      <div class="ticket" v-if="printWithoutPrices">
        <p class="centered company-name">{{ companyInformation.name }}<br /></p>
        <p class="centered black-box">Venta</p>
        <p
          class="centered company-info"
          v-if="
            saleInformation &&
            saleInformation.CLAVE_ALMACEN &&
            saleInformation.CLAVE_ALMACEN.NOMBRE_ALM
          "
        >
          {{ saleInformation.CLAVE_ALMACEN.NOMBRE_ALM }}
          {{ saleInformation.CLAVE_ALMACEN.CEL_ALM }}
          {{ saleInformation.CLAVE_ALMACEN.EMAIL }}
        </p>

        <p
          class="centered company-info"
          v-if="
            !saleInformation ||
            !saleInformation.CLAVE_ALMACEN ||
            !saleInformation.CLAVE_ALMACEN.NOMBRE_ALM ||
            !saleInformation.CLAVE_ALMACEN.DOMICIL
          "
        >
          Dirección: {{ companyInformation.DOMICIL }}
          {{ companyInformation.NUM_INTER }},
          {{ companyInformation.NOMBRE_COLONIA }},
          {{ companyInformation.CP }}
          {{ companyInformation.NOMBRE_MUNICIPIO }},
          {{ companyInformation.ESTADO }}
        </p>

        <p
          class="centered company-info"
          v-if="
            saleInformation &&
            saleInformation.CLAVE_ALMACEN &&
            saleInformation.CLAVE_ALMACEN.NOMBRE_ALM &&
            saleInformation.CLAVE_ALMACEN.DOMICIL
          "
        >
          Dirección: {{ saleInformation.CLAVE_ALMACEN.DOMICIL }}
          {{ saleInformation.CLAVE_ALMACEN.NUM_INTER }},
          {{ saleInformation.CLAVE_ALMACEN.NOMBRE_COLONIA }},
          {{ saleInformation.CLAVE_ALMACEN.CP }}
          {{ saleInformation.CLAVE_ALMACEN.NOMBRE_MUNICIPIO }},
          {{ saleInformation.CLAVE_ALMACEN.ESTADO }}
        </p>
        <div class="ticket-information">
          <p class="ticket-number">
            Ticket: #{{ saleInformation.CLAVE_VENTA }}
          </p>
          <p class="ticket-date">
            Fecha:
            {{
              moment(saleInformation.FECHA)
                .tz("America/Mexico_City")
                .format("DD/MM/yyyy hh:mm")
            }}
          </p>
        </div>
        <table>
          <thead>
            <tr>
              <th class="description-no-price">Concepto</th>
              <th class="quantity-no-price">Cantidad</th>
            </tr>
          </thead>
          <!-- eslint-disable vue/no-use-v-if-with-v-for -->
          <tbody>
            <tr
              v-for="singleItem in saleItems"
              :key="singleItem.RENGLON"
              class="ticket-item"
              v-if="singleItem.article"
            >
              <td class="description-no-price">
                {{ singleItem.quantity }}
                /
                {{
                  singleItem.article.UNIDAD_EMP
                    ? singleItem.article.UNIDAD_EMP.DESCRIP_MED
                    : "PIEZA"
                }}
                /
                {{ singleItem.article.CLAVE_ART }}
                <br />
                {{
                  singleItem.presentation === "UB"
                    ? singleItem.article.DESCRIPCION
                    : singleItem.presentationName
                }}
              </td>
              <td class="quantity-no-price">{{ singleItem.quantity }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="saleInformation.CLAVE_VENTA" class="barcode-all-container">
          <barcode
            :value="saleInformation.CLAVE_VENTA"
            height="32px"
            width="1rem"
            text-align="center"
            font-size="14px"
            class="barcode-container-no-price"
          >
            Código de barras no disponible
          </barcode>
        </div>

        <p class="centered observations">{{ saleInformation.OBSERVA_IMP }}</p>
        <p class="centered thank-you">
          Atendido por:
          {{
            saleInformation.NOMBRE_VENDEDOR
              ? saleInformation.NOMBRE_VENDEDOR
              : ""
          }}
        </p>
        <p class="centered thank-you">¡Gracias por su preferencia!</p>
      </div>
    </div>

    <div class="container-print hidden-print">
      <div class="add-global-controls">
        <b-button type="is-success" @click="print(0)"
          >Imprimir o descargar ticket con precios</b-button
        >

        <b-button type="is-success" @click="print(1)"
          >Imprimir o descargar ticket sin precios</b-button
        >

        <b-button
          type="is-danger"
          @click="closeModal()"
          class="cancel-button"
          v-if="!hideClose"
          >Regresar</b-button
        >

        <b-button
          type="is-danger"
          @click="closeParentModal()"
          class="cancel-button"
          v-if="hideClose"
          >Cerrar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import VueBarcode from "vue-barcode";
import { EventBus } from "@/event-bus";
import html2pdf from "html2pdf.js";

// @ is an alias to /src
export default {
  name: "PrintTicketModal",
  props: [
    "saleInformation",
    "saleId",
    "type",
    "hideClose",
    "isPreSale",
    "isQuote",
  ],
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      moment: moment,
      printWithoutPrices: false,
    };
  },
  mounted() {
    moment.locale("es");
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeParentModal() {
      EventBus.$emit("closeParentModal", {});
    },
    /**
     * @desc print the sale in a thermal ticket
     */
    async print(typeOfPrint) {
      if (typeOfPrint === 1) {
        this.printWithoutPrices = true;

        setTimeout(() => {
          this.printWithoutPrices = false;
        }, 1000);
      }

      setTimeout(async () => {
        let pdfOptions = {
          filename: `test.pdf`,
          pagebreak: {
            avoid: [
              ".ticket-item",
              ".totals-container",
              ".barcode-all-container",
              ".observations",
            ],
          },
          scale: 2,
          image: { type: "jpeg", quality: 0.98 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
          margin: [0, 0.2, 0, 0.2],
        };
        await html2pdf()
          .set(pdfOptions)
          .from(this.$refs.ticket)
          .toPdf()
          .get("pdf")
          .then(function (pdfObj) {
            // pdfObj has your jsPDF object in it, use it as you please!
            // For instance (untested):
            pdfObj.autoPrint();
            window.open(pdfObj.output("bloburl"), "_blank");
          });
      }, 200);
    },
  },
  computed: {
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    saleItems() {
      return this.saleInformation.currentItems.map((singleItem) => {
        const singleItemWithProps = { ...singleItem };
        if (singleItem.article) {
          if (
            singleItem.presentation === "UB" &&
            singleItem.article.UNIDAD_EMP
          ) {
            singleItemWithProps.presentationName =
              singleItem.article.UNIDAD_EMP.DESCRIP_MED;
          } else if (
            singleItem.presentation === "UB" &&
            !singleItem.article.UNIDAD_EMP
          ) {
            singleItemWithProps.presentationName = "Unidad de medida base";
          } else {
            // Set presentation name
            for (const singlePresentation of singleItem.article
              .PRESENTACIONES) {
              if (singlePresentation._id === singleItem.presentation) {
                singleItemWithProps.presentationName =
                  singlePresentation.NOMBRE;
              }
            }
          }

          if (!singleItem.finalPrice) {
            singleItemWithProps.finalPrice = singleItem.price;
          }
        }
        return singleItemWithProps;
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";

.ticket-container {
  display: flex;
  margin-bottom: 1.5rem;
}

.ticket .company-name {
  font-size: 16px;
  padding-bottom: 12px;
  padding-top: 6px;
}

.ticket .black-box {
  padding: 0;
  background: black;
  color: white;
}

.ticket .company-info {
  padding-top: 12px;
  padding-bottom: 6px;
}

.ticket .ticket-number {
  text-align: center;
  padding-bottom: 6px;
}

.ticket .ticket-date {
  text-align: center;
  padding-bottom: 6px;
}

.ticket .barcode-container {
  text-align: center;
}

.ticket .barcode-container-no-price {
  padding-top: 12px;
  text-align: center;
}

.vue-barcode-element {
  width: 100% !important;
}

.ticket .thank-you {
  padding-bottom: 12px;
}

.observations {
  padding-bottom: 12px;
}

.ticket * {
  font-size: 12px;
  font-family: "Times New Roman";
  background: white;
  padding-left: 12px;
  padding-right: 12px;
}

.ticket td,
th,
tr,
table {
  border-top: 1px solid black;
  border-collapse: collapse;
}

.ticket td.description,
.ticket th.description {
  width: 280px;
  max-width: 280px;
  word-break: break-all;
}

.ticket td.description-no-price,
.ticket th.description-no-price {
  width: 280px;
  max-width: 280px;
  word-break: break-all;
}

.ticket td.quantity-no-price,
.ticket th.quantity-no-price {
  width: 110px;
  max-width: 110px;
  word-break: break-all;
  text-align: right;
}

.ticket td.price,
.ticket th.price {
  width: 110px;
  max-width: 110px;
  word-break: break-all;
  text-align: right;
}

.quantity {
  text-align: right;
}

.ticket .centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 330px;
  max-width: 330px;
}

.ticket img {
  max-width: inherit;
  width: inherit;
}

.totals-container {
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
}

.subtotal {
  padding: 0;
  font-weight: bold;
  text-align: end;
  padding-top: 1.5rem;
}

.iva {
  padding: 0;
  font-weight: bold;
  text-align: end;
}

.ieps {
  padding: 0;
  font-weight: bold;
  text-align: end;
}

.totals {
  padding: 0;
  font-weight: bold;
  text-align: end;
  padding-bottom: 1.5rem;
}

@media print {
  .ticket .hidden-print,
  .hidden-print * {
    display: none !important;
  }

  .ticket-main-container .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}
</style>
