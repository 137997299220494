<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Usar secreto</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Secreto a buscar (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': secretRequiredErrors.SECRETO }"
        :message="{
          'La descripción del secreto no es válida':
            secretRequiredErrors.SECRETO,
        }"
      >
        <b-input
          placeholder="Ejemplo: Lista de precios mayoreo"
          expanded
          icon="numeric"
          v-model="secret.SECRETO"
          required
          password-reveal
          type="password"
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="searchSecret()"
        >Buscar secreto</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { EventBus } from "../../../event-bus";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import { fillObjectForm } from "../../../utils/component-fns";
// @ is an alias to /src
export default {
  name: "SearchSecretModal",
  props: ["secretInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      secret: {
        SECRETO: "",
      },
      secretRequiredErrors: {
        SECRETO: false,
      },
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async searchSecret(type) {
      try {
        this.secretRequiredErrors = validateFields(
          this.secret,
          this.secretRequiredErrors
        );
        if (checkValidationErrors(this.secretRequiredErrors)) {
          let response = await this.$store.dispatch("SEARCHSECRET", {
            secret: this.secret.SECRETO,
          });
          if (response.secretFound) {
            EventBus.$emit("secretFound", {
              secret: response.secret,
            });

            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se encontró exitosamente el secreto",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "No se encontró el secreto buscado",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al buscar el secreto en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al buscar el secreto en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
