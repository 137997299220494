<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!providerInformation">
      Agregar Proveedor
    </h1>

    <div v-if="!providerInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field
      label="Tipo de proveedor (Requerido)"
      expanded
      label-position="inside"
      class="special-margin required-input"
      required
    >
      <b-select
        placeholder="Ejemplo: Proveedor"
        icon="account-tie"
        expanded
        v-model="newProvider.TIPO_PRV"
      >
        <option value="PR">Proveedor</option>
        <option value="AC">Acreedor</option>
        <option value="PA">Proveedor/Acreedor</option>
      </b-select>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Nombre (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': providerRequiredErrors.NOMBRE_PRV }"
        :message="{
          'El nombre del proveedor no es valido':
            providerRequiredErrors.NOMBRE_PRV,
        }"
      >
        <b-input
          placeholder="Ejemplo: Proveedor 1"
          expanded
          icon="account-circle-outline"
          v-model="newProvider.NOMBRE_PRV"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Código interno del proveedor (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': providerRequiredErrors.CLIENTE_C_PRV }"
        :message="{
          'El Código interno del proveedor no es valido':
            providerRequiredErrors.CLIENTE_C_PRV,
        }"
      >
        <b-input
          placeholder="Ejemplo: 000001"
          expanded
          icon="numeric"
          v-model="newProvider.CLIENTE_C_PRV"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="RFC (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: CUPU800825569"
          expanded
          icon="numeric-2-box-multiple-outline"
          v-model="newProvider.RFC_PRV"
        ></b-input>
      </b-field>
      <!--
      <b-field label="CURP (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: MAHJ280603MSPRRV09"
          expanded
          icon="numeric-3-box-multiple-outline"
          v-model="newProvider.CURP_PRV"
        ></b-input>
      </b-field>
      -->
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="URL (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: https://url.com"
          expanded
          icon="search-web"
          v-model="newProvider.URL_PRV"
        ></b-input>
      </b-field>
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Proveedor de martillos"
          expanded
          icon="magnify"
          v-model="newProvider.OBSERVACIONES"
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Dirección</h3>

    <b-field grouped class="special-margin">
      <b-field expanded label-position="inside">
        <template slot="label">
          Código Postal (Opcional)
          <b-tooltip
            multilined
            label="Al llenar el código postal la información de estado, municipio, ciudad y colonia es autocompletada"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: 00000"
          expanded
          icon="directions-fork"
          v-model="newProvider.CP_PRV"
          v-cleave="masks.customCP"
          @input="(value) => searchForDirectionInformation(value)"
          type="number"
          step="any"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Dirección (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Paseo del árbol #229"
          expanded
          icon="file-outline"
          v-model="newProvider.DOMICIL_PRV"
        ></b-input>
      </b-field>
      <b-field label="Referencia (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Casa azul"
          expanded
          icon="file-outline"
          v-model="newProvider.REFERENCIA"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Número exterior (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 250"
          expanded
          icon="counter"
          v-model="newProvider.NUM_EXTER"
        ></b-input>
      </b-field>
      <b-field
        label="Número interior (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 10"
          expanded
          icon="counter"
          v-model="newProvider.NUM_INTER"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Municipio (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Nuevo León"
          expanded
          icon="sign-real-estate"
          v-model="newProvider.NOMBRE_MUNICIPIO"
        ></b-input>
      </b-field>
      <b-field
        label="Clave de municipio (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 001"
          expanded
          icon="city-variant-outline"
          v-model="newProvider.MUNICIPIO_PRV"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Estado (Opcional)" expanded label-position="inside">
        <b-select
          placeholder="Ejemplo: Nuevo León"
          expanded
          icon="sign-real-estate"
          v-model="newProvider.ESTADO_PRV"
        >
          <option value="" disabled>Seleccione un estado</option>
          <option
            v-for="singleState in states"
            :value="singleState.CLAVE"
            :key="singleState.NOMBRE"
          >
            {{ singleState.NOMBRE }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Clave de la localidad (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 01"
          expanded
          icon="sign-real-estate"
          v-model="newProvider.LOCALIDAD"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field expanded label-position="inside">
        <template slot="label">
          Nombre de la Colonia (Opcional)
          <b-tooltip
            multilined
            label="El listado de colonias disponibles aparece una vez llenado el código postal"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-autocomplete
          :data="filteredColonies"
          field="colony"
          open-on-focus
          placeholder="Ejemplo: Del Norte"
          expanded
          icon="home-group"
          v-model="newProvider.NOMBRE_COLONIA"
          clearable
          @select="(option) => (newProvider.NOMBRE_COLONIA = option.colony)"
        >
          <template #empty>No se encontraron colonias</template>
        </b-autocomplete>
      </b-field>

      <b-field
        label="Clave de la colonia (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 0001"
          expanded
          icon="sign-real-estate"
          v-model="newProvider.COLONIA_PRV"
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Información de contacto</h3>

    <b-field grouped class="special-margin">
      <b-field label="Nombre (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Pedro"
          expanded
          icon="account-box-outline"
          v-model="newProvider.NOMBRE"
        ></b-input>
      </b-field>
      <!--
      <b-field
        label="Nombre abreviado (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pedro"
          expanded
          icon="account-multiple-outline"
          v-model="newProvider.NOMB_ABREV"
        ></b-input>
      </b-field>
      -->
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Apellido Paterno (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Rodriguez"
          expanded
          icon="account-multiple-outline"
          v-model="newProvider.PATER"
        ></b-input>
      </b-field>
      <b-field
        label="Apellido Materno (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pérez"
          expanded
          icon="account-child"
          v-model="newProvider.MATER"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Teléfono (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: 3300000000"
          expanded
          icon="phone"
          v-model="newProvider.TELS_PRV"
          v-cleave="masks.customPhone"
        ></b-input>
      </b-field>
      <b-field
        label="Teléfono celular (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 3300000000"
          expanded
          icon="cellphone"
          v-model="newProvider.CEL_PRV"
          v-cleave="masks.customPhone"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Email (Opcional)" expanded label-position="inside">
        <b-taginput
          placeholder="Ejemplo: compañia@mail.com"
          expanded
          v-model="newProvider.EMAIL"
          ellipsis
          icon="label"
          :before-adding="(tagToAdd) => validateEmail(tagToAdd)"
        >
        </b-taginput>
      </b-field>
    </b-field>

    <!--
       <h3 class="form-division">Información de pago</h3>
       <b-field grouped class="special-margin">
         <b-switch v-model="soonPaymentActive">
           Aplica descuento por pronto pago
         </b-switch>
       </b-field>

       <b-field grouped class="special-margin">
         <b-field label="Porcentaje de descuento por pronto pago (Requerido)" expanded label-position="inside" class="required-input special-margin" v-if="soonPaymentActive">
           <b-input placeholder="Ejemplo: 5" expanded type="number" icon="label-percent-outline" min="1" v-model="newProvider.PORC_DPPP" required ></b-input>
         </b-field>
       </b-field>
       -->

    <h3 class="form-division">Información de compras</h3>

    <b-field grouped class="special-margin">
      <!--
         <b-switch v-model="newProvider.PAGA_FLETE" true-value="S" false-value="N">
           Paga flete
         </b-switch>
         -->
      <b-switch v-model="creditActive" class="special-margin">
        Se tiene crédito
      </b-switch>
      <!--
         <b-switch v-model="newProvider.FIRMA_PAGARE_IF">
           Se firma pagare
         </b-switch>
         -->
    </b-field>

    <b-field grouped class="special-margin" v-if="creditActive">
      <b-field
        label="Días de crédito (Requerido)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 3300000000"
          expanded
          type="number"
          step="any"
          icon="calendar-range"
          min="1"
          v-model="newProvider.DIAS_CRD"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Limite de Credito (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="2"
          placeholder="Ejemplo: 300000"
          icon="credit-card-plus-outline"
          :required="true"
          v-model="newProvider.LIMITE_CRD"
          :disabled="false"
        />
      </b-field>
    </b-field>

    <b-field
      label="Porcentaje de descuento (Opcional)"
      expanded
      label-position="inside"
    >
      <PercentageInput
        placeholder="Ejemplo: 4"
        expanded
        type="number"
        step="any"
        icon="percent"
        v-model="newProvider.PORC_DESCUENTO"
      />
    </b-field>

    <!--
    <b-field
      label="Inicio de fecha de vencimiento (Requerido)"
      expanded
      label-position="inside"
      class="special-margin required-input"
      required
      :type="{ 'is-danger': providerRequiredErrors.TIPO_VENCE }"
      :message="{
        'El tipo del inicio de fecha de vencimiento del proveedor no es valida':
          providerRequiredErrors.TIPO_VENCE,
      }"
    >
      <b-select
        placeholder="Ejemplo: Proveedor"
        expanded
        icon="calendar-clock"
        v-model="newProvider.TIPO_VENCE"
      >
        <option value="F">Fecha factura</option>
        <option value="R">Fecha Recepcion</option>
      </b-select>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cuota mensual de compra (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 30000"
          expanded
          type="number"
          icon="cash-multiple"
          min="1"
          v-model="newProvider.CUOTA_M_COMPRA"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Compra mínima para no pagar de flete (Opcional)"
        label-position="inside"
        expanded
        v-if="newProvider.PAGA_FLETE === 'S'"
      >
        <b-input
          type="number"
          placeholder="Ejemplo: 10"
          icon="cart-arrow-up"
          expanded
          v-model="newProvider.COM_MIN_P_FLETE"
        ></b-input>
      </b-field>
      <b-field
        label="Tiempo de entrega en días (Opcional)"
        label-position="inside"
        expanded
      >
        <b-input
          type="number"
          placeholder="Ejemplo: 10"
          icon="update"
          expanded
          v-model="newProvider.TIEMPO_E"
        ></b-input>
      </b-field>
    </b-field>
    -->

    <h3 class="form-division">Información de pago</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Número de cuenta (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 12345"
          expanded
          icon="account-cash"
          v-model="newProvider.CUENTA_PRV"
        />
      </b-field>
      <b-field expanded label-position="inside">
        <template slot="label">
          Banco (Opcional)
          <b-tooltip
            multilined
            label="Si no encuentras el banco puedes agregarlo en la sección de 'Catálogo de bancos'"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-select
          placeholder="Ejemplo: Producto"
          expanded
          icon="bank"
          v-model="newProvider.BANCO_PRV"
          @input="handleBankInput"
        >
          <option value="">Seleccione un banco</option>
          <option
            v-for="singleBankAccount in banks"
            :value="singleBankAccount"
            :key="singleBankAccount._id"
          >
            {{ singleBankAccount.NOMBRE }}
          </option>
          <option value="addNewItem">+ Agregar un banco</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="CLABE Intebancaria (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 000000000000000000"
          expanded
          icon="counter"
          v-model="newProvider.CLABE_PRV"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field expanded label-position="inside">
        <template slot="label">
          Nombre de identificador (Opcional)
          <b-tooltip
            multilined
            label="Nombre de algun identificador extra de la cuenta por ejemplo clabe o referencia"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: CLABE interbancaria"
          expanded
          icon="numeric-6-box-multiple-outline"
          v-model="newProvider.NOMBRE_IDENT_PRV"
        />
      </b-field>
      <b-field expanded label-position="inside">
        <template slot="label">
          Número de indentificador (Opcional)
          <b-tooltip
            multilined
            label="Número de algun identificador extra de la cuenta por ejemplo clabe o referencia"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: 000000000000000000"
          expanded
          icon="numeric-9-box-multiple-outline"
          v-model="newProvider.NUM_IDENT_PRV"
        />
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="providerInformation">
      <b-button type="is-success" @click="saveProvider(1)"
        >Actualizar proveedor</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!providerInformation">
      <b-button type="is-success" @click="saveProvider(0)"
        >Crear proveedor</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import { EventBus } from "../../../event-bus";
import { fillObjectForm } from "../../../utils/component-fns";
import AddBankModal from "../../Bank/components/AddBankModal";
import AddProviderBankAccount from "./AddProviderBankAccount";
import AddProviderContact from "./AddProviderContact";
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import Masks from "../../../data/cleave-masks";
import States from "../../../data/states";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AddProviderModal",
  props: ["providerInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newProvider: {
        CLIENTE_C_PRV: "",
        NOMBRE_PRV: "",
        TIPO_PRV: "PR",
        STATUS: "AC",
        RFC_PRV: "",
        CURP_PRV: "",
        DOMICIL_PRV: "",
        NUM_EXTER: "",
        NUM_INTER: "",
        REFERENCIA: "",
        CP_PRV: "",
        NOMBRE_COLONIA: "",
        COLONIA_PRV: "",
        ESTADO_PRV: "",
        LOCALIDAD: "",
        NOMBRE_MUNICIPIO: "",
        MUNICIPIO_PRV: "",
        TELS_PRV: "",
        CEL_PRV: "",
        URL_PRV: "",
        OBSERVACIONES: "",
        TIPO_PERSONA: "F",
        EMAIL: [],
        DIAS_CRD: 0,
        LIMITE_CRD: 0,
        TIPO_VENCE: "F",
        PAGA_FLETE: "N",
        FORM_PAGO: "EF",
        PORC_DPPP: 0,
        PATER: "",
        MATER: "",
        NOMBRE: "",
        NOMB_ABREV: "",
        TIEMPO_E: 0,
        COM_MIN_P_FLETE: 0,
        CUOTA_M_COMPRA: 0,
        FIRMA_PAGARE_IF: false,
        CUENTA_PRV: "",
        BANCO_PRV: "",
        CLABE_PRV: "",
        NOMBRE_IDENT_PRV: "",
        NUM_IDENT_PRV: "",
        PORC_DESCUENTO: 0,
      },
      providerRequiredErrors: {
        CLIENTE_C_PRV: false,
        NOMBRE_PRV: false,
      },
      currentColonies: [],
      soonPaymentActive: false,
      creditActive: false,
      cardActive: false,
      masks: Masks,
      states: States,
    };
  },
  async mounted() {
    // Fill the provider information in form fields
    if (this.providerInformation) {
      // Fill the form with received information
      fillObjectForm(this.providerInformation, this.newProvider);
      this.newProvider._id = this.providerInformation._id;

      if (
        this.providerInformation.DIAS_CRD > 0 ||
        this.providerInformation.LIMITE_CRD > 0
      ) {
        this.creditActive = true;
      }
    }

    if (this.newProvider.CP_PRV.length === 5) {
      let response = await this.$store.dispatch("SEARCHDIRECTIONINFORMATION", {
        zipCode: this.newProvider.CP_PRV,
      });
      if (response.length > 0) {
        this.currentColonies = response;
      }
    }

    EventBus.$on("sendAddProviderBankAccount", (accounts) => {
      this.newProvider.CUEN_BANC = accounts;
    });
    EventBus.$on("sendProvidersContacts", (contacts) => {
      this.newProvider.CONTACT = contacts;
    });
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     *
     * @desc saves or update a provider depending on type
     */
    async saveProvider(type) {
      try {
        this.providerRequiredErrors = validateFields(
          this.newProvider,
          this.providerRequiredErrors
        );
        if (checkValidationErrors(this.providerRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEPROVIDER" : "EDITPROVIDER",
            this.newProvider
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el proveedor",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el proveedor en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el proveedor en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el proveedor en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newProvider = {
        CLIENTE_C_PRV: "",
        NOMBRE_PRV: "",
        TIPO_PRV: "PR",
        STATUS: "AC",
        RFC_PRV: "",
        CURP_PRV: "",
        DOMICIL_PRV: "",
        NUM_EXTER: "",
        NUM_INTER: "",
        REFERENCIA: "",
        CP_PRV: "",
        NOMBRE_COLONIA: "",
        COLONIA_PRV: "",
        ESTADO_PRV: "",
        LOCALIDAD: "",
        NOMBRE_MUNICIPIO: "",
        MUNICIPIO_PRV: "",
        TELS_PRV: "",
        CEL_PRV: "",
        URL_PRV: "",
        OBSERVACIONES: "",
        TIPO_PERSONA: "F",
        EMAIL: [],
        DIAS_CRD: 0,
        LIMITE_CRD: 0,
        TIPO_VENCE: "F",
        PAGA_FLETE: "N",
        FORM_PAGO: "EF",
        PORC_DPPP: 0,
        PATER: "",
        MATER: "",
        NOMBRE: "",
        NOMB_ABREV: "",
        TIEMPO_E: 0,
        COM_MIN_P_FLETE: 0,
        CUOTA_M_COMPRA: 0,
        FIRMA_PAGARE_IF: false,
        CUENTA_PRV: "",
        BANCO_PRV: "",
        CLABE_PRV: "",
        NOMBRE_IDENT_PRV: "",
        NUM_IDENT_PRV: "",
      };
    },
    openAddProvidersBankAccounts() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddProviderBankAccount,
        props: {
          providersAccount: this.newProvider.CUEN_BANC,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddProvidersContact() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddProviderContact,
        props: {
          providersContacts: this.newProvider.CONTACT,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     * @desc searches for direction information when zip has the corret format
     */
    async searchForDirectionInformation(zipCode) {
      try {
        if (zipCode.length === 5) {
          let response = await this.$store.dispatch(
            "SEARCHDIRECTIONINFORMATION",
            { zipCode }
          );
          if (response.length > 0) {
            this.newProvider.ESTADO_PRV = response[0].state_code;
            this.newProvider.NOMBRE_MUNICIPIO = response[0].municipality;
            this.newProvider.MUNICIPIO_PRV = response[0].municipio_code;
            this.newProvider.LOCALIDAD = response[0].localidad_code;
            this.newProvider.NOMBRE_COLONIA = response[0].colony;
            this.newProvider.COLONIA_PRV = response[0].colony_code;
            this.currentColonies = response;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    openAddBank() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddBankModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    handleBankInput() {
      if (this.newProvider.BANCO_PRV === "addNewItem") {
        this.openAddBank();
        setTimeout(() => {
          this.newProvider.BANCO_PRV = "";
        }, 500);
      }
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  computed: {
    banks() {
      return this.$store.getters.BANKS.map((singleBank) => {
        return { ...singleBank };
      }).sort((a, b) => (a.NOMBRE > b.NOMBRE ? 1 : -1));
    },
    commercialBusiness() {
      return this.$store.getters.COMMERCIALBUSINESS.map(
        (singleCommercialBusiness) => {
          const commercialBusinessWithProps = { ...singleCommercialBusiness };
          if (commercialBusinessWithProps.TIPO === "C") {
            commercialBusinessWithProps.typeFormatted = "Clientes";
          } else if (commercialBusinessWithProps.TIPO === "P") {
            commercialBusinessWithProps.typeFormatted = "Proveedores";
          }
          return commercialBusinessWithProps;
        }
      ).filter((singleCommercialBusiness) => {
        return singleCommercialBusiness.TIPO === "P";
      });
    },
    filteredColonies() {
      return this.currentColonies.filter((singleColony) => {
        return (
          singleColony.colony
            .toString()
            .toLowerCase()
            .indexOf(this.newProvider.NOMBRE_COLONIA.toLowerCase()) >= 0
        );
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
