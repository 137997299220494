var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Generar poliza de "),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(_vm._s(_vm.polizaName)+" ")])]),_vm._m(0),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Fecha de inicio (Requerido)","label-position":"inside","expanded":""}},[_c('b-datepicker',{staticClass:"global-lists-filters-control margin required-input",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de inicio","icon":"calendar-today","trap-focus":""},model:{value:(_vm.polizaFilters.startDate),callback:function ($$v) {_vm.$set(_vm.polizaFilters, "startDate", $$v)},expression:"polizaFilters.startDate"}})],1),_c('b-field',{attrs:{"label":"Fecha de fin (Requerido)","label-position":"inside","expanded":""}},[_c('b-datepicker',{staticClass:"global-lists-filters-control required-input",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de fin","icon":"calendar-today","trap-focus":""},model:{value:(_vm.polizaFilters.endDate),callback:function ($$v) {_vm.$set(_vm.polizaFilters, "endDate", $$v)},expression:"polizaFilters.endDate"}})],1)],1),_c('b-field',{staticClass:"special-margin required-input",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Almacenes (Requerido)","expanded":"","label-position":"inside"}},[_c('b-taginput',{attrs:{"data":_vm.warehouses,"autocomplete":"","allow-new":false,"open-on-focus":true,"field":"NOMBRE_ALM","icon":"warehouse","ellipsis":"","placeholder":"Nombre de alamacen","aria-close-label":"Borrar almacen seleccionado","check-infinite-scroll":""},on:{"add":function (value) { return _vm.addWarehouse(value); },"remove":function (value) { return _vm.removeWarehouse(value); }},model:{value:(_vm.polizaFilters.selectedWarehouses),callback:function ($$v) {_vm.$set(_vm.polizaFilters, "selectedWarehouses", $$v)},expression:"polizaFilters.selectedWarehouses"}})],1)],1),_c('h3',{staticClass:"form-division"},[_vm._v("Forma de división de poliza")]),(_vm.dayFilter)?_c('b-checkbox',{staticClass:"permissions-checkbox first-row-checkbox special-margin",attrs:{"disabled":_vm.disableCheckboxFilters && !_vm.polizaFilters.dayFilter},on:{"input":function (value) { return _vm.handleCheckboxFilter(value, 'dayFilter'); }},model:{value:(_vm.polizaFilters.dayFilter),callback:function ($$v) {_vm.$set(_vm.polizaFilters, "dayFilter", $$v)},expression:"polizaFilters.dayFilter"}},[_vm._v(" Día ")]):_vm._e(),_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.generatePoliza(0)}}},[_vm._v("Generar poliza")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])])}]

export { render, staticRenderFns }